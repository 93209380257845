import { AuthTypes } from "../constants/actionTypes";
import { AuthUrls } from "../constants/urls";
import store from "../store";
import { getUserToken } from "../utils/authUtils";
import axios from "axios";
import {SubmissionError} from "redux-form";




// GET DESIGN ORDER
function setGetDesignOrder(payload) {
    return {
        type: AuthTypes.GET_DESIGN_ORDERS,
        payload: payload
    };
}

export function getDesignOrder() {
    return function(dispatch) {
        const token = getUserToken(store.getState());
        if (token) {
            axios.get(AuthUrls.GET_DESIGN_ORDERS, {
                headers: {
                    authorization: 'Token ' + token
                }
            }).then(response => {
                dispatch(setGetDesignOrder(response.data))
            }).catch((error) => {
                const processedError = processServerError(error.response.data);
                throw new SubmissionError(processedError);
            });
        }
    };
}
// DELETE DESIGN ORDER
function setDeleteDesignOrder(id) {
    return {
        type: AuthTypes.DELETE_DESIGN_ORDER,
        payload: id
    };
}

export function deleteDesignOrder(id) {
    return function(dispatch) {
        const token = getUserToken(store.getState());
        if (token) {
            axios.delete(AuthUrls.DELETE_DESIGN_ORDER + id, {
                headers: {
                    authorization: 'Token ' + token
                }
            }).then(response => {
                dispatch(setDeleteDesignOrder(id))
            }).catch((error) => {
                const processedError = processServerError(error.response.data);
                throw new SubmissionError(processedError);
            });
        }
    };
}

// ADD DESIGN ORDER
function setAddDesignOrder(payload) {
    return {
        type: AuthTypes.ADD_DESIGN_ORDER,
        payload: payload
    };
}

export function addDesignOrder(formValues) {
    return function(dispatch) {
        const token = getUserToken(store.getState());
        if (token) {
            axios.post(AuthUrls.ADD_DESIGN_ORDER, formValues, {
                headers: {
                    authorization: 'Token ' + token
                }
            }).then(response => {
                dispatch(setAddDesignOrder(response.data))
            }).catch((error) => {
                const processedError = processServerError(error.response.data);
                throw new SubmissionError(processedError);
            });
        }
    };
}

function processServerError(error) {
    return  Object.keys(error).reduce(function(newDict, key) {
        if (key === "non_field_errors") {
            newDict["_error"].push(error[key]);
        } else if (key === "token") {
            // token sent with request is invalid
            newDict["_error"].push("The link is not valid any more.");
        } else {
            newDict[key] = error[key];
        }

        return newDict
    }, {"_error": []});
}

// SEND NOTIFICATIONS
function setEmail() {
    return {
        type: AuthTypes.SEND_EMAIL_DESIGN,
    };
}

export function getNotification() {
    return function(dispatch) {
        const token = getUserToken(store.getState());
        if (token) {
            axios.get(AuthUrls.SEND_EMAIL_DESIGN, {
                headers: {
                    authorization: 'Token ' + token
                }
            }).then(response => {
                dispatch(setEmail())
            }).catch((error) => {
                // If request is bad...
                // Show an error to the user
                console.log(error);
                //
            });
        }
    };
}