import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import GetStepContent from "./GetStepContent";

import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import {addDesignOrder, getNotification} from '../../../actions/designOrderActions'
import faker from 'faker'


const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    //marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
    [theme.breakpoints.only('xs')]:{

    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

const steps = ['Fill out this form', 'Review your data', 'Important Notice'];

function Checkout(props) {
  const classes = useStyles();
  const {addDesignOrder, designErrors, getNotification} = props;
  const [activeStep, setActiveStep] = React.useState(0);

  const [design, setDesign] = React.useState({
      designName: "",
      designChosen: "",
      numberOfPages: "",
      check: false,
      requirement: "",
      address: "",
      city: "",
      province: "",
      postalCode: "",
      country: "",
      orderNumber: faker.random.number()
  });


  const handleChange = name => event => {
    setDesign({ ...design, [name]: event.target.value });
    console.log(design)
  };
  const handleChangeCheck = name => event => {
    setDesign({ ...design, [name]: event.target.checked});
  };


  const handleSubmit = e => {
     e.preventDefault();
     console.log(design);
     addDesignOrder(design);
     getNotification();
  };

  /*****
  useEffect(() => {
      validation()
  });
*****/

  const handleNext = () => {
    //setActiveStep(activeStep + 1);
    if(design.designName === ""){
      setActiveStep(activeStep );
    } else if (design.designChosen === ""){
      setActiveStep(activeStep );
    } else if(design.requirement === ""){
      setActiveStep(activeStep );
    } else if(design.city === ""){
      setActiveStep(activeStep );
    } else if(design.country === ""){
      setActiveStep(activeStep );
    } else {
       setActiveStep(activeStep + 1)
    }



    //validation();
    //console.log(design)
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Typography component="h1" variant="h5" align="center">
            Design Order Form
          </Typography>
          <Stepper activeStep={activeStep} className={classes.stepper}>
            {steps.map(label => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <React.Fragment>
            {activeStep === steps.length ? (
              <React.Fragment>
                <Typography variant="h5" gutterBottom>
                  Thank you for your order.
                </Typography>
                <Typography variant="subtitle1">
                  Your order number is #{design.orderNumber}. We have emailed your order confirmation, and will
                  send you a proforma invoice to pay 25% before we start working on your order.
                </Typography>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <form onSubmit={handleSubmit}>
                  <GetStepContent step={activeStep} handleChange={handleChange}
                                  design={design} handleChangeCheck={handleChangeCheck}
                                  handleSubmit={handleSubmit} myerror={designErrors}
                  />
                  <div className={classes.buttons}>
                    {activeStep !== 0 && (
                      <Button onClick={handleBack} className={classes.button}>
                        Back
                      </Button>
                    )}
                     {activeStep === steps.length - 1 ?
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      className={classes.button}
                      type={'submit'}
                    >
                      Place order
                    </Button>
                    :
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      className={classes.button}
                    >
                      Next
                    </Button> }
                  </div>
                </form>
              </React.Fragment>
            )}
          </React.Fragment>
        </Paper>
      </main>
    </React.Fragment>
  );
}

Checkout.propTypes = {
  addDesignOrder: PropTypes.func.isRequired,
  getNotification: PropTypes.func.isRequired
};
const mapStateToProps = state => ({
  designErrors: state.designReducer
});

export default connect(mapStateToProps, {addDesignOrder, getNotification})(Checkout)
/**
 const mapStateToProps = state => ({
  designErrors: state.errorsReducer
});

  {getStepContent(activeStep)}
 */