import React, {Fragment} from 'react';
import 'typeface-ubuntu'
import 'typeface-exo'
import {Footer, ServicesTabs, Steps, Team, Testimonials} from '../components'
import { MainPagePromotion, MainCard} from '../components/promotions/components'



export default function Home() {
  return (
      <Fragment>
          <div style={{backgroundColor: '#fff', overflowX: "hidden"}}>
             <MainPagePromotion>
                  <MainCard/>
             </MainPagePromotion>
             <Steps/>
             <ServicesTabs/>
             <Testimonials/>
             <Team/>
             <Footer/>
          </div>
      </Fragment>

  );
}

