import {Typography, Button} from "@material-ui/core";
import {Link} from 'react-router-dom'
import React, {Component, Fragment} from "react";
import {withStyles} from "@material-ui/core";
import {angular, spring, vue_js, css3, html5, django, js, react_js, sql_colour} from '../../common/images'

import {useStyles} from '../../common/styles'
import {ContainerRowStart, ItemSimple, PaperAfterContainer} from "../abstractions";
import * as routes from "../../routes";


export default withStyles(useStyles)(class CorporateWebEngineering extends Component {
    handleAlert=()=>{
        alert("Menu contextuel!");
        return false;
    };
       render(){
          const {classes} = this.props;
        return (
            <Fragment>
                <Typography variant={'overline'} onContextMenu={this.handleAlert} align={'center'} className={classes.mar}>
                    We build around these technologies, and open you recommend one.
                </Typography>
                <ContainerRowStart spacing={1} className={classes.mar}>
                    <ItemSimple>
                        <PaperAfterContainer elevation={0}>
                            <ContainerRowStart spacing={1}>
                                <ItemSimple md={3}>
                                    <img src={angular} alt={'Angular'}/>
                                </ItemSimple>
                                <ItemSimple md={3}>
                                    <img src={django} alt={'django'}/>
                                </ItemSimple>
                                <ItemSimple md={3}>
                                    <img src={react_js} alt={'ReactJs'}/>
                                </ItemSimple>
                                <ItemSimple md={3}>
                                    <img src={css3} alt={'CSS3'}/>
                                </ItemSimple>
                                <ItemSimple md={2}>
                                    <img src={spring} alt={'Spring5'}/>
                                </ItemSimple>
                                <ItemSimple md={2}>
                                    <img src={vue_js} alt={'VueJs'}/>
                                </ItemSimple>
                                <ItemSimple md={2}>
                                    <img src={sql_colour} alt={'SQL'}/>
                                </ItemSimple>
                                <ItemSimple md={2}>
                                    <img src={html5} alt={'html5'}/>
                                </ItemSimple>
                                <ItemSimple md={2}>
                                    <img src={js} alt={'Javascript'}/>
                                </ItemSimple>
                            </ContainerRowStart>
                        </PaperAfterContainer>
                    </ItemSimple>
                    <ItemSimple>
                        <PaperAfterContainer className={classes.pad}>
                            <Typography variant={'overline'} inline={true} color={'default'}>
                               Choose your technology, we do the rest!
                            </Typography>
                            <Typography variant={'body2'}>
                               Sign in and send to us what matters to your and get it done quickly.
                            </Typography>
                            <Link to={routes.SIGN_UP} className={classes.linkNoTextDeco}>
                              <Button variant={'contained'} size={'medium'} fullWidth={false} color={'secondary'} align={'center'} className={classes.mar}>
                                order yours
                              </Button>
                            </Link>
                        </PaperAfterContainer>
                    </ItemSimple>
                </ContainerRowStart>
            </Fragment>
        )
    }
})

