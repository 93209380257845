import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Title from './mainDashboard/Title.js'


const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));


export default function Hosting({handleHosting}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
        <Title>
           Hosting and Server Set Up
        </Title>
      <List component="nav" aria-label="Main mailbox folders">
        <ListItem button onClick={() => handleHosting(7)}>
          <ListItemText primary="Shared Hosting" />
        </ListItem>
         <Divider />
        <ListItem button onClick={() => handleHosting(7)}>
          <ListItemText primary="Virtual Private Server" />
        </ListItem>
        <Divider />
        <ListItem button onClick={() => handleHosting(7)}>
          <ListItemText primary="Domains Services" />
        </ListItem>
        <Divider />
        <ListItem button onClick={() => handleHosting(7)}>
           <ListItemText primary="Maintenance" />
        </ListItem>
        <Divider />
        <ListItem button onClick={() => handleHosting(7)}>
           <ListItemText primary="Want more?" />
        </ListItem>
         <Divider />
      </List>
    </div>
  );
}
