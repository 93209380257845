import { AuthTypes } from "../constants/actionTypes";

const initialState = {
    orderPayment: []
};

export default function(state = initialState, action) {
    if (action.type === AuthTypes.GET_ORDERS_PAYMENTS) {
        return { ...state, orderPayment: action.payload};
    } else {
        return state;
    }
}