import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

export default function Review() {

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
            <Typography variant="h5" gutterBottom>
              Important Notice!
            </Typography>
            <Typography variant="subtitle1">
              After agreement, you need to pay 25% at least of your proforma invoice before we start working on your order.
                Thanks for doing business with us and contact us anytime you have any concerns.
            </Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}