import React from 'react';
import {Link} from 'react-router-dom'
import classNames from 'classnames'
import './remote.css'
import { makeStyles } from '@material-ui/core/styles';
import SwipeableTextMobileStepper from './list_services_show'
import {Hidden, Grid, Paper, Typography, Button} from "@material-ui/core";
import * as routes from '../../routes'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#fff',
    [theme.breakpoints.between('md', 'xl')]:{
      marginBottom: 170,
      marginTop: 40,
    },
  },
  paper: {
    padding: theme.spacing(2),
    margin: 'auto',
    [theme.breakpoints.between('md', 'xl')]: {
      marginBottom: 50,
    },
  },
  remote: {
    [theme.breakpoints.between('md', 'xl')]:{
      fontSize: 90,
      fontWeight: 500,

    },
    [theme.breakpoints.only('sm')]:{
      fontSize: 50,
      fontWeight: 500,
    },
    [theme.breakpoints.only('xs')]:{
      fontSize: 40,
      fontWeight: 500,
    },
  },
  title: {
    [theme.breakpoints.between('md', 'xl')]:{
      fontSize: 30,
      letterSpacing: -2,
      fontWeight: 500,
    },
    [theme.breakpoints.only('sm')]:{
      fontSize: 20,
       letterSpacing: -1,
      fontWeight: 500,
    },
    [theme.breakpoints.only('xs')]:{
      fontSize: 18,
       letterSpacing: -1,
      fontWeight: 500,
    },
  },
   linkNoTextDeco: {
      textDecoration: 'none',
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  },
}));

export default function ProductMarketing() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={0}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={7} md={7} container direction={'column'} justify={'center'} alignItems={'center'}>
            <Grid item >
              <Typography variant={'overline'} className={classes.title}>
                   Professional Services
              </Typography>
              <Typography gutterBottom={true} className={classNames(classes.remote, 'remote2')}>
                  Remote or On-site?
              </Typography>
            </Grid>
            <Grid item>
                 <Typography variant={'overline'} gutterBottom={true}>
                     Prices starting at $75 an hour
                 </Typography>
              <Hidden xsDown={true}>
                <Link to={routes.SIGN_UP} className={classes.linkNoTextDeco}>
                  <Button variant={'contained'} fullWidth={true} href={'/sign-in'} color={'secondary'} size={'large'}>
                    Place Order Now
                   </Button>
                </Link>
              </Hidden>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={5} md={5} container direction={'row'} justify={'center'} alignItems={'center'}>
             <SwipeableTextMobileStepper/>
          </Grid>
          <Hidden smUp>
             <Grid item xs={12} >
               <Button variant={'contained'} fullWidth={true} href={'/sign-in'} color={'secondary'} size={'large'}>
                  PLACE YOUR ORDER
               </Button>
              </Grid>
          </Hidden>
        </Grid>
      </Paper>
    </div>
  );
}
