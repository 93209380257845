import React, {useEffect} from 'react';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Title from './Title';

import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import {getOrderPayments} from "../../../actions/ordersPaymentsActions";

/********
// Generate Order Data
function createData(id, date, name, shipTo, paymentMethod, amount) {
  return { id, date, name, shipTo, paymentMethod, amount };
}

const rows = [
  createData(0, '16 Mar, 2019', 'Elvis Presley', 'Tupelo, MS', 'VISA ⠀•••• 3719', 312.44),
  createData(1, '16 Mar, 2019', 'Paul McCartney', 'London, UK', 'VISA ⠀•••• 2574', 866.99),
  createData(2, '16 Mar, 2019', 'Tom Scholz', 'Boston, MA', 'MC ⠀•••• 1253', 100.81),
  createData(3, '16 Mar, 2019', 'Michael Jackson', 'Gary, IN', 'AMEX ⠀•••• 2000', 654.39),
  createData(4, '15 Mar, 2019', 'Bruce Springsteen', 'Long Branch, NJ', 'VISA ⠀•••• 5919', 212.79),
];

 *****/
const useStyles = makeStyles(theme => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
}));

function Orders(props) {
  const classes = useStyles(1);
   const { getOrderPayments, OrderPaymentsMapped } = props;

   useEffect(() => {
    getOrderPayments();
   }, [getOrderPayments]);

  return (
    <React.Fragment>
      <Title>Recent Orders</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Ship To</TableCell>
            <TableCell>Order Number</TableCell>
            <TableCell align="right">Payment Method</TableCell>
            <TableCell align="right">Sale Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {OrderPaymentsMapped.map(row => (
            <TableRow key={row.id}>
              <TableCell>{row.order_date}</TableCell>
              <TableCell>{row.websiteAppName}</TableCell>
              <TableCell>{row.city}</TableCell>
              <TableCell>{row.orderNumber}</TableCell>
              <TableCell align="right">{row.payment_method}</TableCell>
              <TableCell align="right">${row.amountCloud}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <div className={classes.seeMore}>
        <Link color="primary" href="#">
          See more orders
        </Link>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = state => ({
  OrderPaymentsMapped: state.ordersPaymentsReducer.orderPayment,
});

Orders.propTypes = {
  OrderPaymentsMapped: PropTypes.array.isRequired,
  getOrderPayments: PropTypes.func.isRequired
};

export default connect(mapStateToProps,
    {getOrderPayments})(Orders);
