import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Button, Typography} from '@material-ui/core';
import Title from '../mainDashboard/Title.js';

import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import {getOrderPayments} from "../../../actions/ordersPaymentsActions";

const useStyles = makeStyles({
  depositContext: {
    flex: 1,
  },
});

function Consultancy(props) {
  const classes = useStyles();
  const { myValue, getOrderPayments, OrderPaymentsMapped } = props;

  useEffect(() => {
    getOrderPayments();
   }, [getOrderPayments]);

  const orderPaymentList = OrderPaymentsMapped.map((eachOrder, index) =>
           <React.Fragment key={index}>
               <Title>Consultancy Last Order</Title>
                   <Typography component="p" variant="h4">
                       ${eachOrder.amountConsultancy}
                   </Typography>
                   <Typography color="textSecondary" className={classes.depositContext}>
                       on {eachOrder.payment_date}
                   </Typography>
               <div>
                   <Button color={'primary'} size={'small'} variant={'text'} onClick={() => myValue(12)}>
                       view past order
                   </Button>
               </div>
           </React.Fragment>
   );
  return (
    <React.Fragment>
        { OrderPaymentsMapped.length === 0 ?
            <React.Fragment>
               <Title>Consultancy Last Order</Title>
                   <Typography component="p" variant="h4">
                       $0
                   </Typography>
                   <Typography color="textSecondary" className={classes.depositContext}>
                       on N/A
                   </Typography>
               <div>
                   <Button color={'primary'} size={'small'} variant={'text'} onClick={() => myValue(12)}>
                       view past order
                   </Button>
               </div>
           </React.Fragment>

        :
            <React.Fragment>
               {orderPaymentList}
            </React.Fragment>
        }
      </React.Fragment>
  );
}

const mapStateToProps = state => ({
  OrderPaymentsMapped: state.ordersPaymentsReducer.orderPayment,
});

Consultancy.propTypes = {
  OrderPaymentsMapped: PropTypes.array.isRequired,
  getOrderPayments: PropTypes.func.isRequired
};

export default connect(mapStateToProps,
    {getOrderPayments})(Consultancy);