import React, {Fragment} from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';
import {Grid, Paper} from '@material-ui/core'
import {useStyles} from "../../common/styles";
import PropTypes from "prop-types";
import {monquoWhitesvg} from '../../common/images'

import 'typeface-ubuntu'
import 'typeface-exo'
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';


const theme = createMuiTheme({
  palette: {
    primary: { main: "#fff" }, // Purple and green play nicely together.
 // This is just green.A700 as hex.
  },
  typography: {
      fontFamily: '"Ubuntu", "Exo", "Roboto"'
  },
  shadows: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],


});

function StickyFooter(props) {
  const {classes} = props;

  return (
    <div className={classes.root_footer}>
      <CssBaseline />
      <ThemeProvider theme={theme}>
          <footer className={classes.footer}>
              <Fragment>
                    <Grid container color={'secondary'} className={classes.container_footer}>
                        <Grid item xs={12} sm={12} md={8}>
                            <Paper className={classes.paper_footer}>
                                <Typography variant={'h6'} color={'primary'}>
                                  <img src={monquoWhitesvg} alt={'logo'} className={classes.logo}/>
                                </Typography>
                                <Typography variant={'body2'} color={'primary'}>
                                    Copyright 2019 &copy;   | Terms of services | Privacy and policy.
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4}>
                             <Paper className={classes.paper_footer}>
                                <Typography color={'primary'} variant={'body2'}>
                                    Contact us
                                </Typography>
                                 <Typography color={'primary'} variant={'body1'}>
                                   Phone: 306 500 3195
                                </Typography>
                                 <Typography color={'primary'}>
                                    Email: info@djeumene.ca
                                </Typography>
                            </Paper>
                        </Grid>
                    </Grid>
              </Fragment>
          </footer>
      </ThemeProvider>

    </div>
  );
}

StickyFooter.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(useStyles)(StickyFooter);