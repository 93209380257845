import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  line: {
    [theme.breakpoints.up('md')]: {
        width: 1500,
        marginLeft: 150,
        display: 'block',
        //borderWidth: 3,
        border: 0,
        borderTop: '2px solid #e92262',

    },
    [theme.breakpoints.down('sm')]: {
        width: 400,
        marginLeft: 19,
        display: 'block',
        //borderWidth: 3,
        border: 0,
        borderTop: '2px solid #e92262',
    },
  },
}));

export default function Line() {
  const classes = useStyles();

  return (
      <hr className={classes.line}/>
  );
};
