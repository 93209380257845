import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

export default function EmailsForm({handleChange, myDesign}) {
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Complete the form
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="emailSubject"
            name="emailSubject"
            label="Subject"
            error={myDesign.emailSubject === ""}
            fullWidth
            autoComplete="emailSubject"
            onChange={handleChange("emailSubject")}
            value={myDesign.emailSubject}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            type={"email"}
            id="emailAddress"
            name="emailAddress"
            label="Your email address"
            error={myDesign.emailAddress === ""}
            fullWidth
            autoComplete="emailAddress"
            multiline
            onChange={handleChange("emailAddress")}
            value={myDesign.emailAddress}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            required
            id="body"
            name="body"
            label="Write message here (20 lines max)"
            error={myDesign.body === ""}
            fullWidth
            autoComplete="thanks"
            multiline
            rowsMax={20}
            onChange={handleChange("body")}
            value={myDesign.body}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}