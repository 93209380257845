import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {withStyles, Typography, Button} from '@material-ui/core'
import classNames from 'classnames'
import {useStyles} from '../common/styles'
import {monquo, robotSvg} from '../common/images'
import {ContainerRow, PaperAfterContainer, ItemSimple} from '../components/abstractions'

import {renderTextField, validate} from "../utils/renderUtils";
import {Field, reduxForm} from "redux-form";
import { resetPassword } from "../actions/authActions";
import {Link} from "react-router-dom";
import * as routes from "../routes";
import Grid from "@material-ui/core/Grid";

class ForgotPassword extends Component {
    render(){
        const {classes} = this.props;
        this.handleCopyRight=(e)=> {
         e.preventDefault();
         return false;
     };
        return(
            <div className={classes.root}>
                <ContainerRow>
                    <ItemSimple xs={12} sm={6} md={6}>
                        <img src={robotSvg} alt={"Robot Engineering"} className={classes.robot} onContextMenu={this.handleCopyRight} onMouseDown={(e) => e.preventDefault()}/>
                    </ItemSimple>
                    <ItemSimple>
                        <PaperAfterContainer className={classes.paperWidthPw}>
                            <Typography gutterBottom={true} align={'center'}>
                               <img src={monquo} alt={'logo'} onContextMenu={this.handleCopyRight} onMouseDown={(e) => e.preventDefault()}/>
                            </Typography>
                            <Typography gutterBottom={true} align={'center'} variant={'h6'}>
                             Reset password here!
                            </Typography>
                            <form method={'post'}>
                                <Field
                                  variant="outlined"
                                  label="Email Address"
                                  name="email"
                                  type={'email'}
                                  autoComplete="email"
                                  component={renderTextField}
                                />
                                <Button
                                    type={'submit'}
                                    color={'secondary'}
                                    variant={'contained'}
                                    size={'medium'}
                                    fullWidth={true}
                                    className={classNames(classes.mar, classes.bigMar)}
                                >
                                    reset
                                </Button>
                            </form>
                            <Grid container  className={classes.bottomSpace}>
                                <Grid item xs>
                                  <Link to={routes.HOME} variant="body2" className={classes.textDeco}>
                                    Back to home page?
                                  </Link>
                                </Grid>
                            </Grid>
                        </PaperAfterContainer>
                    </ItemSimple>
                </ContainerRow>
            </div>
        )
    }
}

ForgotPassword.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default reduxForm({
    form: "reset_password",
    validate: validate,
    onSubmit: resetPassword,
 })(withStyles(useStyles)(ForgotPassword))