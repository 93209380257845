import React from 'react';
import DesignForm from './DesignForm';
import ReviewDataForm from './ReviewDataForm';
import Review from './Review';


export default function GetStepContent({step, handleChange, design, handleChangeCheck}) {
  let result = null;
  switch (step) {
    case 0:
      result = <DesignForm handleChange={handleChange} myDesign={design} handleChangeCheck={handleChangeCheck}/>;
      break;
    case 1:
      result = <ReviewDataForm myDesignDisable={design}/>;
      break;
    case 2:
      result = <Review />;
      break;
    default:
      throw new Error('Unknown step');
  }
  return result;
}
