import { AuthTypes } from "../constants/actionTypes";

const initialState = {
    contact: []
};

export default function(state = initialState, action) {
    switch(action.type) {
        case AuthTypes.GET_EMAIL_ORDER:
            return { ...state, contact: action.payload};
        case AuthTypes.DELETE_EMAIL_ORDER:
            return { ...state, contact: state.contact.filter(contactId => contactId.id !== action.payload)};
        case AuthTypes.ADD_EMAIL_ORDER:
            return { ...state, contact: [...state.contact, action.payload]};
        default:
            return state;
    }
}