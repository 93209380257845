import React from "react";
import useStyles from "../styles";
import {Grid} from "@material-ui/core";

import {Header} from '../../index'

const MainPagePromotion = (props) => {
    const classes = useStyles();
    const { children} = props;
    const [title, setTitle] = React.useState('');
    const getTitleFromChild = (childTitle) => setTitle(childTitle);

    const updateChildrenWithProps = React.Children.map(
        children,
        (child, i) => {
            return React.cloneElement(child, {
                getTitleFromChild: getTitleFromChild,
                index: i
            })
        }
    );
    return (
        <div className={classes.container}>
            <Header/>
            <h1 className={classes.title}>{title}</h1>
            <Grid container direction={'row'} justify={'center'} alignItems={'center'} className={classes.adjust_margins}>
                {updateChildrenWithProps}
            </Grid>
        </div>
    )
};


export default MainPagePromotion;