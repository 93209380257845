import React, {Component} from 'react';
import PropTypes from 'prop-types';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { Paper, Avatar, Button, CssBaseline, Typography} from "@material-ui/core";
import withStyles from '@material-ui/core/styles/withStyles';
import {ContainerRowStart, ItemSimple, ContainerRowLineCentered} from "../components/abstractions";
import {Link} from "react-router-dom";
import * as routes from "../routes";
import {monquo, robotSvg} from "../common/images";
import {useStyles} from "../common/styles";

import { reduxForm, Field} from "redux-form";
import {loginUser} from "../actions/authActions";
import {renderTextField, validate} from "../utils/renderUtils";
import asyncValidate from "../utils/asyncValidate";



export class SignIn extends Component{

  render(){

     const { classes, handleSubmit} = this.props;

     this.handleCopyRight=(e)=> {
         e.preventDefault();
         return false;
     };
     return (
         <ContainerRowLineCentered>
           <ItemSimple xs={12} sm={6} md={6}>
                 <img src={robotSvg} alt={"Robot Engineering"} className={classes.robot} onContextMenu={this.handleCopyRight} onMouseDown={(e) => e.preventDefault()}/>
           </ItemSimple>
           <ItemSimple xs={12} sm={6} md={6}>
               <main className={classes.main}>
                <CssBaseline />

                <Paper className={classes.paper}>
                  <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                  </Avatar>
                  <Typography gutterBottom={true} align={'center'}>
                    <img src={monquo} alt={'logo'} onContextMenu={this.handleCopyRight} onMouseDown={(e) => e.preventDefault()}/>
                  </Typography>
                  <Typography component="h1" variant="h6">
                   Authentication
                  </Typography>
                  <form className={classes.form} onSubmit={handleSubmit}>
                    <Field
                        variant="outlined"
                        label="Email Address"
                        name="email"
                        type={'email'}
                        autoComplete="email"
                        component={renderTextField}
                      />
                    <Field
                        variant="outlined"
                        label="Password"
                        name="password"
                        type={'password'}
                        autoComplete="password"
                        component={renderTextField}
                      />
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="secondary"
                      className={classes.submit}
                    >
                      Sign in
                    </Button>
                  </form>
                  <ContainerRowStart>
                      <ItemSimple>
                          <Typography gutterBottom={true} className={classes.pad}>
                              Need to reset your password? <Link to={routes.PASSWORD_RESET} >Click here </Link>
                          </Typography>
                      </ItemSimple>
                  </ContainerRowStart>
                </Paper>
              </main>
           </ItemSimple>
         </ContainerRowLineCentered>

  );
}
}

SignIn.propTypes = {
  classes: PropTypes.object.isRequired,
   login: PropTypes.func.isRequired,
   isAuthenticated: PropTypes.bool
};

export default reduxForm({
    form: "sign_in",
    validate: validate,
    onSubmit: loginUser,
    asyncValidate
})(withStyles(useStyles)(SignIn));