import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import * as routes from '../../routes'
import Link from '@material-ui/core/Link'
import {Link as Links}from 'react-router-dom'
import {useStyles} from "../../common/styles";
import {monquoColorsvg} from '../../common/images'


import 'typeface-ubuntu'
import 'typeface-exo'
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';



const theme = createMuiTheme({
  palette: {
    primary: { main: "#fff" }, // Purple and green play nicely together.
 // This is just green.A700 as hex.
  },
  typography: {
      fontFamily: '"Ubuntu", "Exo", "Roboto"'
  },
   shadows: [0],


});
function ButtonAppBar(props) {
  const { classes } = props;
  return (
    <div className={classes.root} color={'primary'}>
      <ThemeProvider theme={theme}>
          <AppBar position={'static'}>
            <Toolbar>
               <ThemeProvider theme={theme}>
                   <Typography variant={'h6'} color={'inherit'} className={classes.root}>
                      <img src={monquoColorsvg} alt={'logo'} className={classes.logo}/>
                   </Typography>
               </ThemeProvider>
               <ThemeProvider theme={theme}>
                   <Link to={'https://fr.monquo.studio'} target={''} className={classes.linkNoTextDeco}>
                      <Button color= 'default' href={'https://fr.monquo.studio'}>FR</Button>
                  </Link>
               </ThemeProvider>
               <ThemeProvider theme={theme}>
                   <Links to={routes.SIGN_IN} className={classes.linkNoTextDeco}>
                      <Button color= {'secondary'} variant={'contained'} >
                        Login
                      </Button>
                  </Links>
               </ThemeProvider>
            </Toolbar>
          </AppBar>
      </ThemeProvider>

    </div>
  );
}

ButtonAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(useStyles)(ButtonAppBar);