import React from "react";
import useStyles from "../styles";
import {Button, Grid, Paper} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CloudIcon from '@material-ui/icons/CloudOutlined'
import HttpIcon from '@material-ui/icons/HttpOutlined'
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import DnsIcon from '@material-ui/icons/Dns';
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';
import PromotionPrix from "./PromotionPrix";
import {americanExpressImage, masterCardImage, visaImage} from '../images';
import * as routes from "../../../routes";
import {Link} from "react-router-dom";


const MainCard = (props) => {
    const classes = useStyles();
    const {getTitleFromChild} = props;
    const [deal_title, setDeal_Title] = React.useState('');
    const getDealTitleFromChild = (childTitle) => setDeal_Title(childTitle);
    return (
        <Grid container xs={12} sm={12} md={10} className={classes.mainCard}>
            <Grid item xs={12} sm={12} md={6}>
                <h1 className={classes.textTitle}>{deal_title}</h1>
                <Link to={routes.SIGN_UP} className={classes.en_profiter}>
                    <Button variant={'contained'} href={'/sign-in'} color={'secondary'} size={'large'}>
                       sign up now
                    </Button>
                </Link>
            </Grid>
            <Grid container xs={12} sm={12} md={6} justify={"center"} alignItems={'center'} spacing={3}>
                <Grid item xs={12} sm={7} md={10}>
                    <PromotionPrix onGettingTitle = {getDealTitleFromChild} getTitleFromChild={getTitleFromChild}/>
                </Grid>
                <Grid item xs={12} sm={7} md={10} style={{marginLeft: 16}}>
                    <Typography variant={"h6"}>
                        Our products:
                    </Typography>
                    <List aria-label="main mailbox folders">
                        <ListItem>
                            <ListItemIcon style={{color: "#f50057"}}>
                                <HttpIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Professional Websites" />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon style={{color: "#f50057"}}>
                                <CloudIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Professional Cloud Services" />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon style={{color: "#f50057"}}>
                                <SettingsApplicationsIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Design and Application Development" />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon style={{color: "#f50057"}}>
                                <DnsIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Hosting, Domains Sales" />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon style={{color: "#f50057"}}>
                                <DesktopWindowsIcon/>
                            </ListItemIcon>
                            <ListItemText primary="IT Technical Services" />
                        </ListItem>
                    </List>
                </Grid>
            </Grid>
            <Grid container xs={12} sm={12} md={12} direction={'row'} justify={'center'} alignItems={'center'} spacing={2} style={{marginTop: 16}}>
                <Typography>
                    Available payments options:
                </Typography>
                <Grid item>
                    <Paper elevation={0}>
                        <img  src={visaImage} className={classes.payments}   onMouseDown={(e) => e.preventDefault()} alt={'credit card'}/>
                    </Paper>
                </Grid>
                <Grid item>
                    <Paper  elevation={0} >
                        <img  src={americanExpressImage}  className={classes.payments} alt={'credit card'}  onMouseDown={(e) => e.preventDefault()}/>
                    </Paper>
                </Grid>
                <Grid item>
                    <Paper  elevation={0} >
                        <img  src={masterCardImage} className={classes.payments} alt={'credit card'}  onMouseDown={(e) => e.preventDefault()}/>
                    </Paper>
                </Grid>
            </Grid>
        </Grid>
    )
};

export default MainCard;