import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Title from './mainDashboard/Title.js'


const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));


export default function Consultancy({handleConsultancy}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
        <Title>
            Consultancy
        </Title>
      <List component="nav" aria-label="Main mailbox folders">
        <ListItem button onClick={() => handleConsultancy(8)}>
          <ListItemText primary="Servers Set up (Linux, Microsoft)" />
        </ListItem>
         <Divider />
        <ListItem button onClick={() => handleConsultancy(8)}>
          <ListItemText primary="Database Development" />
        </ListItem>
        <Divider />
        <ListItem button onClick={() => handleConsultancy(8)}>
          <ListItemText primary="Micro-services (Parts of Software)" />
        </ListItem>
        <Divider />
        <ListItem button onClick={() => handleConsultancy(8)}>
           <ListItemText primary="Short Term Contracts" />
        </ListItem>
        <Divider />
        <ListItem button onClick={() => handleConsultancy(8)}>
           <ListItemText primary="Want more?" />
        </ListItem>
         <Divider />
      </List>
    </div>
  );
}
