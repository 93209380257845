import React from 'react';
import PropTypes from 'prop-types';
import {Typography, Button, MobileStepper, withStyles, Paper} from '@material-ui/core';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import {app_dev, pro_web, pro_cloud, destra, open_sou, dat_dev} from '../../common/images'


const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const tutorialSteps = [
  {
    label: 'Professional Websites',
    imgPath: pro_web,

  },
  {
    label: 'Applications Development',
    imgPath: app_dev,

  },
  {
    label: 'Open Sources Integrations',
    imgPath: open_sou,

  },
  {
    label: 'Database Development',
    imgPath: dat_dev,

  },
  {
    label: 'Cloud Development',
    imgPath: pro_cloud,

  },
  {
    label: 'Design, Training & Mentoring',
    imgPath: destra,

  },
];

const styles = theme => ({
  root: {
    maxWidth: 400,
    flexGrow: 1,
    backgroundColor: '#fff'
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    height: 50,
    paddingLeft: theme.spacing(4),
    backgroundColor: '#fff',
    [theme.breakpoints.only('xs')]: {
           display: 'none',
      },
    [theme.breakpoints.only('sm')]: {
           display: 'none',
      },
  },
  mobileStepper:{
    backgroundColor: '#fff'
  },
  img: {
    height: 155,  //old value was 255
    display: 'block',
    maxWidth: 400,
    overflow: 'hidden',
    width: '100%',

  },
  colour: {
    color: '#333'
  }
});

class SwipeableTextMobileStepper extends React.Component {
  state = {
    activeStep: 0,
  };

  handleNext = () => {
    this.setState(prevState => ({
      activeStep: prevState.activeStep + 1,
    }));
  };

  handleBack = () => {
    this.setState(prevState => ({
      activeStep: prevState.activeStep - 1,
    }));
  };

  handleStepChange = activeStep => {
    this.setState({ activeStep });
  };

  render() {
    const { classes, theme } = this.props;
    const { activeStep } = this.state;
    const maxSteps = tutorialSteps.length;

    return (
      <div className={classes.root}>
        <Paper square elevation={0} className={classes.header}>
          <Typography variant={'h5'}>{tutorialSteps[activeStep].label}</Typography>
        </Paper>
        <AutoPlaySwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={activeStep}
          onChangeIndex={this.handleStepChange}
          enableMouseEvents
        >
          {tutorialSteps.map((step, index) => (
            <div key={step.label}>
              {Math.abs(activeStep - index) <= 2 ? (
                <img className={classes.img} src={step.imgPath} alt={step.label} />
              ) : null}
            </div>
          ))}
        </AutoPlaySwipeableViews>
        <MobileStepper
          steps={maxSteps}
          position="static"
          activeStep={activeStep}
          className={classes.mobileStepper}
          nextButton={
            <Button size="small" onClick={this.handleNext} disabled={activeStep === maxSteps - 1}>
              Next
              {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </Button>
          }
          backButton={
            <Button size="small" onClick={this.handleBack} disabled={activeStep === 0}>
              {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
              Back
            </Button>
          }
        />
      </div>
    );
  }
}

SwipeableTextMobileStepper.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(SwipeableTextMobileStepper);