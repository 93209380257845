import React, {useState} from 'react';
import PropTypes from 'prop-types';
import './remote.css'
import { makeStyles } from '@material-ui/core/styles';
import {Grid, Paper, Typography, AppBar, Tab, Tabs} from "@material-ui/core";
import {CorporateWebEngineering, Consultancy, HireMe, Line} from '../../components'
import 'typeface-ubuntu'
import 'typeface-exo'
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    [theme.breakpoints.between('md', 'xl')]:{
      marginBottom: 100,
    },[theme.breakpoints.only('xs')]:{
      marginBottom: 50,
    },
  },
  paper: {
    padding: theme.spacing(2),
    margin: 'auto',
    [theme.breakpoints.between('md', 'xl')]:{
      marginBottom: 0,
      marginTop: 130,

    },
  },
  remote: {
    [theme.breakpoints.between('md', 'xl')]:{
      fontSize: 90,
      fontWeight: 500,

    },
    [theme.breakpoints.only('sm')]:{
      fontSize: 50,
      fontWeight: 500,
    },
    [theme.breakpoints.only('xs')]:{
      fontSize: 40,
      fontWeight: 500,
    },
  },
  title: {
    [theme.breakpoints.between('md', 'xl')]:{
      fontSize: 22,
      letterSpacing: -1,
      fontWeight: 500,
      marginLeft: 150,
    },
    [theme.breakpoints.only('sm')]:{
      fontSize: 20,
       letterSpacing: -1,
      fontWeight: 500,
       marginLeft: 150,
    },
    [theme.breakpoints.only('xs')]:{
      fontSize: 18,
       letterSpacing: -1,
      fontWeight: 500,
      marginLeft: 55,
    },
  },
  titleTab: {
    [theme.breakpoints.between('md', 'xl')]:{
      fontSize: 18,
      letterSpacing: -1,
      fontWeight: 500,
    },
    [theme.breakpoints.only('sm')]:{
      fontSize: 16,
       letterSpacing: -1,
      fontWeight: 500,
    },
    [theme.breakpoints.only('xs')]:{
      fontSize: 14,
       letterSpacing: -1,
      fontWeight: 500,
    },
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  },
}));


const theme = createMuiTheme({
  palette: {
    primary: { main: "#fff" }, // Purple and green play nicely together.
 // This is just green.A700 as hex.
  },
  typography: {
      fontFamily: '"Ubuntu", "Exo", "Roboto"'
  },
   shadows: [0],


});

export default function ServicesTabs() {

   const [value, setValue] = useState(0);

  const handleChange = (event, value) => {
    setValue(value );
  };

  const classes = useStyles();

  function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3}}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <Paper className={classes.paper} elevation={0}>
          <Line/>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} container direction={'column'} justify={'center'} alignItems={'flex-start'}>
              <Grid item direction={'column'} justify={'center'} alignItems={'center'}>
                <Typography variant={'overline'} className={classes.title}>
                     Choose What Matters To You?
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        <Paper  elevation={0}>
            <Grid  container>
               <Grid item md={2}> </Grid>
                  <Grid item md={8} sm={12} xs={12}>
                      <AppBar position={'static'} color="common" elevation={0}>
                        <Tabs
                          value={value}
                          onChange={handleChange}
                          indicatorColor="secondary"
                          textColor={'secondary'}
                          variant={'scrollable'}
                          scrollButtons="on"
                          centered={true}
                        >
                          <Tab label="Corporate Web Engineering" className={classes.titleTab} wrapped={true}/>
                          <Tab label="Consultancy" className={classes.titleTab}/>
                          <Tab label="Hire Me" className={classes.titleTab}/>
                        </Tabs>
                      </AppBar>
                      {value === 0 && <TabContainer><CorporateWebEngineering/></TabContainer>}
                      {value === 1 && <TabContainer><Consultancy/></TabContainer>}
                      {value === 2 && <TabContainer><HireMe/></TabContainer>}
                  </Grid>
                  <Grid item md={2}> </Grid>
            </Grid>
        </Paper>
      </div>
  </ThemeProvider>
  );
}
