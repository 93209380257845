import React from 'react';
import {cardBlackImage, cardBlueImage} from "../images";
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
    card: {
        width: "100%",
    },
    cardWhite: {
        width: "100%",
        color: "white",
    },
    title: {
        fontSize: 20,
    },
    price: {
        fontSize: 80,
        fontWeight: "600",
    },
    centerAlign: {
        textAlign: "center",
    },
    cardContent: {
        padding: "16px 16px 8px",
    },
    cardActions: {
        padding: "0 16px 16px",
    },
    rightAlign: {
        margin: "0 0 0 auto !important",
    },
});

const classesJoin = (...classes) => classes.join(" ");

export default function CardTemplate(props) {
    const classes = useStyles();
    const {card_title, card_title_first_line, background_image, card_amount, expire_date, time} = props;

    return (
        <Card className={background_image === cardBlackImage || background_image === cardBlueImage ? classes.cardWhite : classes.card} variant="elevation" style={{
            backgroundImage: `url(${background_image})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
        }}>
            <CardContent className={classes.cardContent}>
                <Typography className={classesJoin(classes.title, classes.centerAlign)} variant={"h5"}>
                    {card_title}
                </Typography>
                <Typography className={classesJoin(classes.title, classes.centerAlign)} variant="h5">
                    {card_title_first_line}
                </Typography>
                <Typography className={classesJoin(classes.price, classes.centerAlign)} variant={"h2"}>
                   ${card_amount}
                </Typography>
            </CardContent>
            <CardActions className={classes.cardActions}>
                <Typography>
                    Expires: {expire_date}
                </Typography>
                <Typography className={classes.rightAlign}>
                    {time}
                </Typography>
            </CardActions>
        </Card>
    );
}
