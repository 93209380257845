export const AuthTypes = {
    LOGIN: "LOGIN",
    LOGOUT: "LOGOUT",
    CHANGE_PASSWORD: "CHANGE_PASSWORD",
    USER_PROFILE: "USER_PROFILE",
    GET_DESIGN_ORDERS: 'GET_DESIGN_ORDERS',
    DELETE_DESIGN_ORDER: 'DELETE_DESIGN_ORDER',
    ADD_DESIGN_ORDER: 'ADD_DESIGN_ORDER',
    ADD_CLOUD_ORDER: 'ADD_CLOUD_ORDER',
    DELETE_CLOUD_ORDER: 'DELETE_CLOUD_ORDER',
    GET_CLOUD_ORDER: 'GET_CLOUD_ORDER',
    ADD_HOSTING_ORDER: 'ADD_HOSTING_ORDER',
    DELETE_HOSTING_ORDER: 'DELETE_HOSTING_ORDER',
    GET_HOSTING_ORDER: 'GET_HOSTING_ORDER',
    ADD_CONSULTANCY_ORDER: 'ADD_CONSULTANCY_ORDER',
    DELETE_CONSULTANCY_ORDER: 'DELETE_CONSULTANCY_ORDER',
    GET_CONSULTANCY_ORDER: 'GET_CONSULTANCY_ORDER',
    ADD_EMAIL_ORDER: 'ADD_EMAIL_ORDER',
    GET_EMAIL_ORDER: 'GET_EMAIL_ORDER',
    DELETE_EMAIL_ORDER: 'DELETE_EMAIL_ORDER',
    GET_RESPONSE_ORDER: 'GET_RESPONSE_ORDER',
    SEND_EMAIL_DESIGN: 'SEND_EMAIL_DESIGN',
    SEND_EMAIL_CLOUD: 'SEND_EMAIL_CLOUD',
    SEND_EMAIL_HOSTING: 'SEND_EMAIL_HOSTING',
    SEND_EMAIL_CONSULTANCY: 'SEND_EMAIL_CONSULTANCY',
    SEND_EMAIL_CONTACT: 'SEND_EMAIL_CONTACT',
    GET_ORDERS_PAYMENTS: 'GET_ORDERS_PAYMENTS',
    GET_DEALS: 'GET_DEALS',
};