import React, {useEffect} from 'react';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Title from './Title';

import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import {getOrderPayments} from "../../../actions/ordersPaymentsActions";

const useStyles = makeStyles({
  depositContext: {
    flex: 1,
  },
});

function Deposits(props) {
  const classes = useStyles(1);
  const { getOrderPayments, OrderPaymentsMapped } = props;

   useEffect(() => {
    getOrderPayments();
   }, [getOrderPayments]);

   const orderPaymentList = OrderPaymentsMapped.map((eachOrder, index) =>
           <React.Fragment key={index}>
               <Title>Last Payment</Title>
                   <Typography component="p" variant="h4">
                       ${eachOrder.amountCloud}
                   </Typography>
                   <Typography color="textSecondary" className={classes.depositContext}>
                       on {eachOrder.payment_date}
                   </Typography>
               <div>
                    <Link color="primary" href="#javascript:;">
                      View balance
                    </Link>
               </div>
           </React.Fragment>
   );

  return (
   <React.Fragment>
       { OrderPaymentsMapped.length === 0 ?
        <React.Fragment>
               <Title>Last Payment</Title>
                   <Typography component="p" variant="h4">
                       $0
                   </Typography>
                   <Typography color="textSecondary" className={classes.depositContext}>
                       on N/A
                   </Typography>
               <div>
                    <Link color="primary" href="#javascript:;">
                      View balance
                    </Link>
               </div>
        </React.Fragment>
       :
           <React.Fragment>
                {orderPaymentList}
           </React.Fragment>
       }
   </React.Fragment>
  );
}

const mapStateToProps = state => ({
  OrderPaymentsMapped: state.ordersPaymentsReducer.orderPayment,
});

Deposits.propTypes = {
  OrderPaymentsMapped: PropTypes.array.isRequired,
  getOrderPayments: PropTypes.func.isRequired
};

export default connect(mapStateToProps,{getOrderPayments})(Deposits);