import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import PaymentIcon from '@material-ui/icons/Payment'
import BarChartIcon from '@material-ui/icons/BarChart';
import {Typography} from "@material-ui/core";


export default function MainListItems ({myValue, value}) {

    return (
        <div >
            <ListItem button onClick = {() => myValue(0)}>
                <ListItemIcon>
                    {value === 0 ?
                    <DashboardIcon color={'primary'}/>
                    : <DashboardIcon color={'inherit'}/>}
                </ListItemIcon>
                <ListItemText disableTypography={true}>
                    {value === 0 ?
                    <Typography color={'primary'}> Dashboard</Typography>
                    :  <Typography> Dashboard</Typography>}
                </ListItemText>
            </ListItem>
            <ListItem button onClick = {() => myValue(1)}>
                <ListItemIcon>
                    {value === 1 ?
                    <ShoppingCartIcon color={'primary'}/>
                    : <ShoppingCartIcon color={'inherit'}/>}
                </ListItemIcon>
                <ListItemText disableTypography={true}>
                    {value === 1 ?
                    <Typography color={'primary'}> Orders</Typography>
                    :  <Typography> Orders</Typography>}
                </ListItemText>
            </ListItem>
            <ListItem button onClick = {() => myValue(2)}>
                <ListItemIcon>
                    {value === 2 ?
                    <PaymentIcon color={'primary'}/>
                    : <PaymentIcon color={'inherit'}/>}
                </ListItemIcon>
                <ListItemText disableTypography={true}>
                    {value === 2 ?
                    <Typography color={'primary'}> Payments</Typography>
                    :  <Typography> Payments</Typography>}
                </ListItemText>
            </ListItem>
            <ListItem button onClick = {() => myValue(3)}>
                <ListItemIcon>
                    {value === 3 ?
                    <BarChartIcon color={'primary'}/>
                    :  <BarChartIcon color={'inherit'}/>}
                </ListItemIcon>
                <ListItemText disableTypography={true}>
                    {value === 3 ?
                    <Typography color={'primary'}> Past Orders</Typography>
                    :  <Typography> Past Orders</Typography>}
                </ListItemText>
            </ListItem>
            <ListItem button  onClick = {() => myValue(4)}>
                <ListItemIcon>
                    { value === 4 ?
                    <ContactMailIcon color={'primary'}/>
                    : <ContactMailIcon color={'inherit'}/> }
                </ListItemIcon>
                <ListItemText disableTypography={true}>
                    {value === 4 ?
                    <Typography color={'primary'}> Contact Us</Typography>
                    :  <Typography> Contact Us</Typography>}
                </ListItemText>
            </ListItem>
        </div>

    );
};

