
const useStyles = theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
   appbar: {
     padding: '20px'
   },
   linkNoTextDeco: {
      textDecoration: 'none',
      marginRight: 10,
  },
  paper_footer: {
    padding: 20,
    marginBottom: 0,
    overflowY: 'auto',
    [theme.breakpoints.up('sm')]: {
       backgroundColor: "#333"
    },
    [theme.breakpoints.down('xs')]: {
       backgroundColor: "#333",
        borderRadius: 0
    }
  },

  container_footer: {
    [theme.breakpoints.up('sm')]: {
      backgroundColor: "#333",

    },
    [theme.breakpoints.down('xs')]: {
       height: '0px',
    },
    item: {
      [theme.breakpoints.down('xs')]: {
           backgroundColor: "#333",
      },
      [theme.breakpoints.up('sm')]: {
      backgroundColor: "#333"
    },
    }

  },
   root_footer: {
    display: 'flex',
    flexDirection: 'column',
    //minHeight: '100vh',
  },
   footer: {
    color: "#fff",
    marginTop: 'auto',
    backgroundColor: 'white',
  },
   pad: {
      padding: theme.spacing.unit * 3,
    },
    mar: {
      marginTop: theme.spacing.unit * 3,
      marginBottom: theme.spacing.unit * 1,
    },
    bigMar: {
      marginTop: theme.spacing.unit * 2,
    },
    paperWidthMd: {
      [theme.breakpoints.up('md')]: {
           marginTop: '100px',
            width: '400px',
            padding: theme.spacing.unit * 3,
        },
        [theme.breakpoints.down('sm')]: {
           marginTop: '70px',
            width: '300px',
            padding: theme.spacing.unit * 3,
        },
        [theme.breakpoints.only('xs')]: {
           marginTop: '70px',
            width: '100vw',
            padding: theme.spacing.unit * 3,
            //marginLeft: 30,
        }
    },
    paperWidthPw: {
      [theme.breakpoints.up('md')]: {
           marginTop: '200px',
            width: '400px',
            padding: theme.spacing.unit * 3,
        },
        [theme.breakpoints.down('sm')]: {
           marginTop: '70px',
            width: '300px',
            padding: theme.spacing.unit * 3,
        },
        [theme.breakpoints.only('xs')]: {
           marginTop: '70px',
            width: '100vw',
            padding: theme.spacing.unit * 3,
            //marginLeft: 30,
        }
    },
    persoTaxPadding: {
        [theme.breakpoints.up('sm')]: {
            paddingTop: theme.spacing.unit * 4,
        }
    },
    logoCus: {
        [theme.breakpoints.up('md')]: {
            height: '80px',
        },
        [theme.breakpoints.down('sm')]: {
            height: '50px',
        },
    },
    logoCusPers: {
        [theme.breakpoints.up('md')]: {
            width: '250px',
        },
        [theme.breakpoints.down('sm')]: {
             width: '180px',
        },
        [theme.breakpoints.only('xs')]: {
             width: '160px',
            marginTop: '15px'
        },
    },
    dealWithUs : {
         height: '175px',
         width: '175px',
         padding: '35px',
         borderRadius: '50%',
   },
    dealWithUsSpace: {

      [theme.breakpoints.down('sm')]: {
          marginTop: '30px',
          marginBottom: '50px'
    },[theme.breakpoints.only('xs')]: {
          marginTop: '30px',
          marginBottom: '50px'
    },
    [theme.breakpoints.up('md')]: {
      marginTop: '100px',
      marginBottom: '190px'
    }
    },
     dealWithUsTypo: {
          marginTop: theme.spacing.unit * 8,
          marginBottom: theme.spacing.unit * 3,
          [theme.breakpoints.down('sm')]: {
          marginTop: '30px',
          marginBottom: '15px',
          fontSize: '15px',
          fontWeight: 'bold'
       },
          fontSize: '22px'
     },
    imgTest: {
    [theme.breakpoints.up('sm')]: {
      backgroundColor: "#333",
      height: '81px',
      width: '129px',
      overflow: 'hidden',
    },
    [theme.breakpoints.down('xs')]: {
       backgroundColor: "#333",
      height: '81px',
      width: '129px',
       overflow: 'hidden',
    },

  },

  title: {
    [theme.breakpoints.between('md', 'xl')]:{
      fontSize: 22,
      letterSpacing: -1,
      fontWeight: 500,
      marginLeft: 150,
    },
    [theme.breakpoints.only('sm')]:{
      fontSize: 20,
      letterSpacing: -1,
      fontWeight: 500,
       marginLeft: 150,
    },
    [theme.breakpoints.only('xs')]:{
      fontSize: 18,
      letterSpacing: -1,
      fontWeight: 500,
      marginLeft: 55,
    },
  },
  logo: {
    [theme.breakpoints.between('md', 'xl')]:{
     width: 200,
    },
    [theme.breakpoints.only('sm')]:{
      width: 170,
    },
    [theme.breakpoints.only('xs')]:{
      width: 150,
    },
  },
  robot: {
    [theme.breakpoints.between('lg', 'xl')]:{
     width: 575,
     marginLeft: 200,
    },
    [theme.breakpoints.only('md')]:{
     width: 575,
    },
    [theme.breakpoints.only('sm')]:{
      width: 250,
    },
    [theme.breakpoints.only('xs')]:{
      width: 150,
      marginLeft: 90,
      marginTop: 15,
    },
  },

  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
    color: '#fff'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
  },
  container1: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper1: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  textDeco: {
    textDecoration: 'None',
    color: '#f50057'
  },
   bottomSpace: {
      marginBottom: 20,
  },

});


export {
    useStyles
}