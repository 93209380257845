import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

export default function EmailsForm({myDesignDisable}) {
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Complete the form
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="emailSubject"
            name="emailSubject"
            label="Subject"
            fullWidth
            autoComplete="emailSubject"
            value={myDesignDisable.emailSubject}
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            type={"email"}
            id="emailAddress"
            name="emailAddress"
            label="Your email address"
            fullWidth
            autoComplete="emailAddress"
            multiline
            value={myDesignDisable.emailAddress}
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            required
            id="body"
            name="body"
            label="Write something here"
            fullWidth
            autoComplete="thanks"
            multiline
            value={myDesignDisable.body}
            disabled
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}