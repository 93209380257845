import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Title from './mainDashboard/Title.js'


const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));


export default function Design() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
        <Title>
           Tools and Programming Language
        </Title>
      <List component="nav" aria-label="Main mailbox folders">
        <ListItem>
          <ListItemText primary="Sketch for Design" />
        </ListItem>
         <Divider />
        <ListItem>
          <ListItemText primary="HTML, CSS & Javascript" />
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText primary="React, Angular & VueJs" />
        </ListItem>
        <Divider />
        <ListItem>
           <ListItemText primary="Java, Python, MongoDB & MySQL" />
        </ListItem>
        <Divider />
        <ListItem>
           <ListItemText primary="Django, Spring 5 & Spring Boot" />
        </ListItem>
         <Divider />
      </List>
    </div>
  );
}
