import { AuthTypes } from "../constants/actionTypes";
import { AuthUrls } from "../constants/urls";
import store from "../store";
import { getUserToken } from "../utils/authUtils";
import axios from "axios";
import {SubmissionError} from "redux-form";




// GET HOSTING ORDER
function setGetHostingOrder(payload) {
    return {
        type: AuthTypes.GET_HOSTING_ORDER,
        payload: payload
    };
}

export function getHostingOrder() {
    return function(dispatch) {
        const token = getUserToken(store.getState());
        if (token) {
            axios.get(AuthUrls.GET_HOSTING_ORDER, {
                headers: {
                    authorization: 'Token ' + token
                }
            }).then(response => {
                dispatch(setGetHostingOrder(response.data))
            }).catch((error) => {
                const processedError = processServerError(error.response.data);
                throw new SubmissionError(processedError);
            });
        }
    };
}
// DELETE HOSTING ORDER
function setDeleteHostingOrder(id) {
    return {
        type: AuthTypes.DELETE_HOSTING_ORDER,
        payload: id
    };
}

export function deleteHostingOrder(id) {
    return function(dispatch) {
        const token = getUserToken(store.getState());
        if (token) {
            axios.delete(AuthUrls.DELETE_HOSTING_ORDER + id, {
                headers: {
                    authorization: 'Token ' + token
                }
            }).then(response => {
                dispatch(setDeleteHostingOrder(id))
            }).catch((error) => {
                const processedError = processServerError(error.response.data);
                throw new SubmissionError(processedError);
            });
        }
    };
}

// ADD HOSTING ORDER
function setAddHostingOrder(payload) {
    return {
        type: AuthTypes.ADD_HOSTING_ORDER,
        payload: payload
    };
}

export function addHostingOrder(formValues) {
    return function(dispatch) {
        const token = getUserToken(store.getState());
        if (token) {
            axios.post(AuthUrls.ADD_HOSTING_ORDER, formValues, {
                headers: {
                    authorization: 'Token ' + token
                }
            }).then(response => {
                dispatch(setAddHostingOrder(response.data))
            }).catch((error) => {
               const processedError = processServerError(error.response.data);
                throw new SubmissionError(processedError);
            });
        }
    };
}


function processServerError(error) {
    return  Object.keys(error).reduce(function(newDict, key) {
        if (key === "non_field_errors") {
            newDict["_error"].push(error[key]);
        } else if (key === "token") {
            // token sent with request is invalid
            newDict["_error"].push("The link is not valid any more.");
        } else {
            newDict[key] = error[key];
        }

        return newDict
    }, {"_error": []});
}

// SEND NOTIFICATIONS
function setEmail() {
    return {
        type: AuthTypes.SEND_EMAIL_HOSTING,
    };
}

export function getNotification() {
    return function(dispatch) {
        const token = getUserToken(store.getState());
        if (token) {
            axios.get(AuthUrls.SEND_EMAIL_HOSTING, {
                headers: {
                    authorization: 'Token ' + token
                }
            }).then(response => {
                dispatch(setEmail())
            }).catch((error) => {
                // If request is bad...
                // Show an error to the user
                console.log(error);
                //
            });
        }
    };
}
