import {combineReducers} from "redux";
import { reducer as formReducer } from "redux-form";
import { reducer as notifReducer } from 'redux-notifications';

import authReducer from "./authReducer";
import designReducer from "./designReducer";
import hostingReducer from "./hostingReducer";
import cloudReducer from "./cloudReducer";
import consultancyReducer from "./consultancyReducer";
import contactReducer from "./contactReducer";
import ordersPaymentsReducer from "./ordersPaymentsReducer";
import dealsTimeReducer from "./dealsTimeReducer";

const rootReducer = combineReducers({
    form: formReducer,
    notifs: notifReducer,
    auth: authReducer,
    designReducer,
    cloudReducer,
    hostingReducer,
    consultancyReducer,
    contactReducer,
    ordersPaymentsReducer,
    dealsTimeReducer
});

export default rootReducer;