import { AuthTypes } from "../constants/actionTypes";
import { AuthUrls } from "../constants/urls";
import store from "../store";
import { getUserToken } from "../utils/authUtils";
import axios from "axios";




// GET CLOUD ORDER
function setGetEmail(payload) {
    return {
        type: AuthTypes.GET_EMAIL_ORDER,
        payload: payload
    };
}

export function getEmail() {
    return function(dispatch) {
        const token = getUserToken(store.getState());
        if (token) {
            axios.get(AuthUrls.GET_EMAIL_ORDER, {
                headers: {
                    authorization: 'Token ' + token
                }
            }).then(response => {
                dispatch(setGetEmail(response.data))
            }).catch((error) => {
                // If request is bad...
                // Show an error to the user
                console.log(error);
                //
            });
        }
    };
}

// DELETE CLOUD ORDER
function setDeleteEmail(id) {
    return {
        type: AuthTypes.DELETE_EMAIL_ORDER,
        payload: id
    };
}

export function deleteEmail(id) {
    return function(dispatch) {
        const token = getUserToken(store.getState());
        if (token) {
            axios.delete(AuthUrls.DELETE_EMAIL_ORDER + id, {
                headers: {
                    authorization: 'Token ' + token
                }
            }).then(response => {
                dispatch(setDeleteEmail(id))
            }).catch((error) => {
                // If request is bad...
                // Show an error to the user
                console.log(error);
                //
            });
        }
    };
}

// ADD CLOUD ORDER
function setAddEmail(payload) {
    return {
        type: AuthTypes.ADD_EMAIL_ORDER,
        payload: payload
    };
}

export function addEmail(formValues) {
    return function(dispatch) {
        const token = getUserToken(store.getState());
        if (token) {
            axios.post(AuthUrls.ADD_EMAIL_ORDER, formValues, {
                headers: {
                    authorization: 'Token ' + token
                }
            }).then(response => {
                dispatch(setAddEmail(response.data))
            }).catch((error) => {
                // If request is bad...
                // Show an error to the user
                console.log(error);
                //
            });
        }
    };
}



// SEND NOTIFICATIONS
function setEmail() {
    return {
        type: AuthTypes.SEND_EMAIL_CONTACT,
    };
}

export function getNotification() {
    return function(dispatch) {
        const token = getUserToken(store.getState());
        if (token) {
            axios.get(AuthUrls.SEND_EMAIL_CONTACT, {
                headers: {
                    authorization: 'Token ' + token
                }
            }).then(response => {
                dispatch(setEmail())
            }).catch((error) => {
                // If request is bad...
                // Show an error to the user
                console.log(error);
                //
            });
        }
    };
}
