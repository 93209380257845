import React from 'react';
import { green, pink } from '@material-ui/core/colors';
import classNames from 'classnames'
import './remote.css'
import { makeStyles } from '@material-ui/core/styles';
import {Grid, Paper, Typography, ListItemText, ListItemAvatar, ListItem, List, Avatar} from "@material-ui/core";
import {CloudQueueOutlined, ThumbUpAltOutlined, AttachMoneyOutlined, LockRounded} from "@material-ui/icons";
import {Line} from '../../components'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    [theme.breakpoints.between('md', 'xl')]:{
      marginBottom: 170,
    },
  },
  paper: {
    padding: theme.spacing(2),
    margin: 'auto',
    [theme.breakpoints.between('md', 'xl')]:{
      marginBottom: 0,
      marginTop: 130,
    },
  },
  remote: {
    [theme.breakpoints.between('md', 'xl')]:{
      fontSize: 90,
      fontWeight: 500,

    },
    [theme.breakpoints.only('sm')]:{
      fontSize: 50,
      fontWeight: 500,
    },
    [theme.breakpoints.only('xs')]:{
      fontSize: 40,
      fontWeight: 500,
    },
  },
  title: {
    [theme.breakpoints.between('md', 'xl')]:{
      fontSize: 22,
      letterSpacing: -1,
      fontWeight: 500,
      marginLeft: 150,
    },
    [theme.breakpoints.only('sm')]:{
      fontSize: 20,
       letterSpacing: -1,
      fontWeight: 500,
       marginLeft: 150,
    },
    [theme.breakpoints.only('xs')]:{
      fontSize: 18,
       letterSpacing: -1,
      fontWeight: 500,
      marginLeft: 55,
    },
  },
   linkNoTextDeco: {
      textDecoration: 'none'
  },
  pinkAvatar: {
    margin: 10,
    color: '#fff',
    backgroundColor: pink[500],
  },
  greenAvatar: {
    margin: 10,
    color: '#fff',
    backgroundColor: green[500],

  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  },
}));

export default function Steps() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={0}>
        <Line/>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} container direction={'column'} justify={'center'} alignItems={'flex-start'}>
            <Grid item direction={'column'} justify={'center'} alignItems={'center'}>
              <Typography variant={'overline'} className={classNames(classes.title)}>
                   How it works?
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <Paper  elevation={0}>
          <Grid  container direction={'row'} justify={'center'} alignItems={'center'} spacing={2} >
              <Grid item xs={12} sm={12} md={2}>
                  <List>
                      <ListItem>
                          <ListItemAvatar>
                              <Avatar className={classes.pinkAvatar}>
                                  <LockRounded/>
                              </Avatar>
                          </ListItemAvatar>
                          <ListItemText primary={'Create An Account & Sign In'}/>
                      </ListItem>
                  </List>
              </Grid>
              <Grid item xs={12} sm={12} md={2}>
                   <List>
                      <ListItem>
                          <ListItemAvatar>
                              <Avatar className={classes.pinkAvatar}>
                                  <CloudQueueOutlined/>
                              </Avatar>
                          </ListItemAvatar>
                          <ListItemText primary={'Order Everything Online'}/>
                      </ListItem>
                  </List>
              </Grid>
              <Grid item xs={12} sm={12} md={2}>
                   <List>
                      <ListItem>
                          <ListItemAvatar>
                              <Avatar className={classes.greenAvatar}>
                                  <AttachMoneyOutlined/>
                              </Avatar>
                          </ListItemAvatar>
                          <ListItemText primary={'Pay Your Invoice Online'}/>
                      </ListItem>
                  </List>
              </Grid>
              <Grid item xs={12} sm={12} md={2}>
                   <List>
                      <ListItem>
                          <ListItemAvatar>
                              <Avatar className={classes.greenAvatar}>
                                  <ThumbUpAltOutlined/>
                              </Avatar>
                          </ListItemAvatar>
                          <ListItemText primary={'That Is All'}/>
                      </ListItem>
                  </List>
              </Grid>
          </Grid>
      </Paper>
    </div>
  );
}
