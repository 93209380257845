import React from 'react';
import StepDesign from './StepDesign'
import StepWeb from './StepWeb'
import StepHosting from './StepHosting'
import StepConsultancy from './StepConsultancy'




export default function StepContentManagement ({constant}){
    let contentValue = null;

    switch (constant) {
        case 5:
            //* Design Form */
           contentValue = <StepDesign/>;
           break;
        case 6:
            //* Web & Cloud Form */
            contentValue = <StepWeb/>;
            break;
        case 7:
            //* Hosting & Services Form */
            contentValue = <StepHosting/>;
            break;
        case 8:
            //* Consultancy Form */
            contentValue = <StepConsultancy/>;
            break;
        default:
            //* Default */
            contentValue = <h2>Some wrong happened!</h2>;
}
 return contentValue;
};
