import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Title from './mainDashboard/Title.js'
import {Typography} from "@material-ui/core";
import StripeCheckout from "react-stripe-checkout";
import {toast} from "react-toastify";
import request from 'request'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import {getOrderPayments} from "../../actions/ordersPaymentsActions";


const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
    container1: {
    marginBottom: theme.spacing(4),
  },
}));

toast.configure({
     autoClose: 8000,
     draggable: false,
});



function MakePayments(props) {
    const classes = useStyles(1);
    const { getOrderPayments, OrderPaymentsMapped } = props;
    const successNotify = () =>  toast.success('🦄 Success! Check email for details!', { position: "top-right", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true});
    const errorNotify = () => toast.error('🦄Something went wrong!', { position: "top-right", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true});
    useEffect(() => {
    getOrderPayments();
   }, [getOrderPayments]);

    const create_customer = (token) => {
        const options = { method: 'POST',
      url: 'https://api.stripe.com/v1/customers',
      headers:
       { 'cache-control': 'no-cache',
         Connection: 'keep-alive',
         'Content-Length': '41',
         'Accept-Encoding': 'gzip, deflate',
         Host: 'api.stripe.com',
         'Postman-Token': '54a229a7-cf7a-4e5a-afa2-95824103c561,eceb3800-df2c-4920-ba6d-e01cf3ee81d6',
         'Cache-Control': 'no-cache',
         Accept: '*/*',
         'User-Agent': 'PostmanRuntime/7.20.1',
         Authorization: 'Bearer sk_test_4MjARjyznCc439rspEKho0xI00nxusQqLX',
         'Content-Type': 'application/x-www-form-urlencoded' },
      form: { email: token.email} };

    request(options, function (error, response, body) {
      if (error) throw new Error(error);
    });
    };
  const handleToken = async (token) => {
    create_customer(token);
    const options = { method: 'POST',
      url: 'https://api.stripe.com/v1/charges',
      headers:
       { 'cache-control': 'no-cache',
         Connection: 'keep-alive',
         'Content-Length': '41',
         'Accept-Encoding': 'gzip, deflate',
         Host: 'api.stripe.com',
         'Postman-Token': '54a229a7-cf7a-4e5a-afa2-95824103c561,eceb3800-df2c-4920-ba6d-e01cf3ee81d6',
         'Cache-Control': 'no-cache',
         Accept: '*/*',
         'User-Agent': 'PostmanRuntime/7.20.1',
         Authorization: 'Bearer sk_test_4MjARjyznCc439rspEKho0xI00nxusQqLX',
         'Content-Type': 'application/x-www-form-urlencoded' },
      form: { amount: infoOrder.amount * 100, currency: 'cad', source: token.id, receipt_email: token.email, description: `Purchased the ${infoOrder.productName}`} };

    request(options, function (error, response, body) {
          if (error){
          throw new Error(error);
      }
           if(body.status === "succeeded"){
              successNotify()
            } else {
                errorNotify()
            }
    });


  };

  const infoOrder = {
      amount: '',
      productName: ''
  };
    // eslint-disable-next-line array-callback-return
   OrderPaymentsMapped.map(eachOrder => {
       infoOrder.amount = eachOrder.amountCloud + eachOrder.amountDesign + eachOrder.amountHosting + eachOrder.amountConsultancy
       infoOrder.productName = eachOrder.websiteAppName;
   });
  return (
    <div className={classes.root}>
        <Title>
           Make a payment
        </Title>
        <Typography gutterBottom={true} className={classes.container1}>
            Please click on the below button to make your payment, thanks!
        </Typography>
         <Divider className={classes.container1}/>
         <StripeCheckout
             token={handleToken}
             stripeKey={'pk_test_GvqKtCRYsqDLgP4AlDVIUyyN00qhtrQ8op'}
             billingAddress
             shippingAddress
             amount={infoOrder.amount * 100}
             currency={'CAD'}
             name={infoOrder.productName}
         />
    </div>
  );
}

const mapStateToProps = state => ({
  OrderPaymentsMapped: state.ordersPaymentsReducer.orderPayment,
});

MakePayments.propTypes = {
  OrderPaymentsMapped: PropTypes.array.isRequired,
  getOrderPayments: PropTypes.func.isRequired
};

export default connect(mapStateToProps,
    {getOrderPayments})(MakePayments);