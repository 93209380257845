import { AuthTypes } from "../constants/actionTypes";

const initialState = {
    designOrder: []
};

export default function(state = initialState, action) {
    switch(action.type) {
        case AuthTypes.GET_DESIGN_ORDERS:
            return { ...state, designOrder: action.payload};
        case AuthTypes.DELETE_DESIGN_ORDER:
            return { ...state, designOrder: state.designOrder.filter(designId => designId.id !== action.payload)};
        case AuthTypes.ADD_DESIGN_ORDER:
            return { ...state, designOrder: [...state.designOrder, action.payload]};
        default:
            return state;
    }
}