import { AuthTypes } from "../constants/actionTypes";

const initialState = {
    hostingOrder: []
};

export default function(state = initialState, action) {
    switch(action.type) {
        case AuthTypes.GET_HOSTING_ORDER:
            return { ...state, hostingOrder: action.payload};
        case AuthTypes.DELETE_HOSTING_ORDER:
            return { ...state, hostingOrder: state.hostingOrder.filter(hostingId => hostingId.id !== action.payload)};
        case AuthTypes.ADD_HOSTING_ORDER:
            return { ...state, hostingOrder: [...state.hostingOrder, action.payload]};
        default:
            return state;
    }
}