import React from 'react';
import Checkout from "./Cloud/CheckoutCloud";


export default function StepDesign() {

  return (
      <Checkout/>
  );
};
