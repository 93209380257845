import {Typography, Button} from "@material-ui/core";
import {Link} from 'react-router-dom'
import React, {Component, Fragment} from "react";
import {withStyles} from "@material-ui/core";
import {database_colour, server_colour, hosting_colour, maintenance_colour} from '../../common/images'

import {useStyles} from '../../common/styles'
import {ContainerRowStart, ItemSimple, PaperAfterContainer} from "../abstractions";
import * as routes from "../../routes";


export default withStyles(useStyles)(class Consultancy extends Component {
    handleAlert=()=>{
        alert("Menu contextuel!");
        return false;
    };
       render(){
          const {classes} = this.props;
        return (
            <Fragment>
                <Typography variant={'overline'} onContextMenu={this.handleAlert} align={'center'} className={classes.mar}>
                    We provide great services around these: dns, dhcp, databases, hosting, cloud and servers.
                </Typography>
                <ContainerRowStart spacing={32} className={classes.mar}>
                    <ItemSimple>
                        <PaperAfterContainer elevation={0}>
                            <ContainerRowStart spacing={16}>
                                <ItemSimple md={3}>
                                    <img src={database_colour} alt={'Databases'}/>
                                </ItemSimple>
                                <ItemSimple md={3}>
                                    <img src={server_colour} alt={'servers'}/>
                                </ItemSimple>
                                <ItemSimple md={3}>
                                    <img src={hosting_colour} alt={'Hosting'}/>
                                </ItemSimple>
                                <ItemSimple md={3}>
                                    <img src={maintenance_colour} alt={'Maintenance'}/>
                                </ItemSimple>
                            </ContainerRowStart>
                        </PaperAfterContainer>
                    </ItemSimple>
                    <ItemSimple>
                        <PaperAfterContainer className={classes.pad}>
                            <Typography variant={'overline'} inline={true} color={'default'}>
                               setup, performance, security, automated scripts, diagnostics ...and more
                            </Typography>
                            <Typography variant={'body2'}>
                               Just sign in and let us get it done quickly.
                            </Typography>
                            <Link to={routes.SIGN_UP} className={classes.linkNoTextDeco}>
                              <Button variant={'contained'} size={'medium'} fullWidth={false} color={'secondary'} align={'center'} className={classes.mar}>
                                Contact us
                              </Button>
                            </Link>
                        </PaperAfterContainer>
                    </ItemSimple>
                </ContainerRowStart>
            </Fragment>
        )
    }
})

