import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';


export default function Review() {

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
            <Typography variant="h5" gutterBottom>
              Important Notice!
            </Typography>
            <Typography variant="subtitle1">
              We will respond you in 48 hours, thanks for contacting us.
            </Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}