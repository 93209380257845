import {makeStyles} from '@material-ui/core/styles';
import {backgroundImage} from "../images";

const useStyles = makeStyles({
    container: {
        background: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",

    },
    adjust_margins: {
        marginLeft: '10px',
        width: '93vw'
    },
    en_profiter: {
        margin: "20px 0 0 64px",

        "@media(max-width: 960px)": {
            display: "block",
            width: "max-content",
            margin: "20px auto 0",
        },
    },
    mainCard: {
        background: "#ffffff",
        width: 'auto',
        paddingBottom: 24,
        borderRadius: 8,
        boxShadow: "0 0 0px rgba(0, 0, 0, 0.2)",
        boxSizing: "border-box",
    },
    title: {
        margin: "0 0 20px",
        background: "linear-gradient(135deg, #1B1BAE, #1D0A3A)",
        "-webkit-background-clip": "text",
        "-webkit-text-fill-color": "transparent",
        font: "84px Pacifico",
        textAlign: "center",

        "@media(max-width: 768px)": {
            font: "64px Pacifico",
            lineHeight: 1,
            padding: "0 0 25px"
        },
    },
    mainCardCon: {
        display: "flex",
    },
    textTitle: {
        margin: "25% 0 30px 64px",
        textShadow: "1px 1px #BB0C49, 2px 2px #BB0C49, 3px 3px #960748, 4px 4px #960748",
        color: "#ff1164",
        font: "96px Pacifico",
        lineHeight: 1,

        "@media(max-width: 1440px)": {
            textShadow: "1px 1px #BB0C49, 2px 2px #960748, 3px 3px #960748",
            font: "80px Pacifico",
            lineHeight: 0.95,
        },

        "@media(max-width: 1024px)": {
            textShadow: "1px 1px #BB0C49, 2px 2px #960748, 3px 3px #960748",
            font: "64px Pacifico",
            lineHeight: 0.9,
        },

        "@media(max-width: 960px)": {
            textAlign: "center",
            margin: "32px 0 20px",
        },

        "@media(max-width: 768px)": {
            textShadow: "1px 1px #BB0C49, 2px 2px #960748",
        },

        "@media(max-width: 520px)": {
            margin: "16px 0 20px",
        },
    },
    payments: {
        width: '60px',
        "@media(max-width: 960px)": {
            width: '40px'
        },
    }
});

export default useStyles;