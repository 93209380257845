import { AuthTypes } from "../constants/actionTypes";

const initialState = {
    cloudOrder: []
};

export default function(state = initialState, action) {
    switch(action.type) {
        case AuthTypes.GET_CLOUD_ORDER:
            return { ...state, cloudOrder: action.payload};
        case AuthTypes.DELETE_CLOUD_ORDER:
            return { ...state, cloudOrder: state.cloudOrder.filter(cloudId => cloudId.id !== action.payload)};
        case AuthTypes.ADD_CLOUD_ORDER:
            return { ...state, cloudOrder: [...state.cloudOrder, action.payload]};
        default:
            return state;
    }
}