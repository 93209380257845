import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from "@material-ui/core/MenuItem";
import {makeStyles} from "@material-ui/core";

const currencies = [
  {
    value: 'Logo',
    label: 'Logo',
  },
  {
    value: 'Website',
    label: 'Website',
  },
  {
    value: 'Desktop Application',
    label: 'Desktop Application',
  },
  {
    value: 'Mobile Application',
    label: 'Mobile Application',
  },
  {
    value: 'Other',
    label: 'Other',
  },
];

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    width: 200,
  },
}));

export default function DesignForm({handleChange, myDesign, handleChangeCheck}) {
   const classes = useStyles();

    /**********
    useEffect(() => {
    handleOrderNumber(orderNumber)
  }, []);
**************/

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Complete the form
      </Typography>
      <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              error={myDesign.designName === ""}
              id="designName"
              name="designName"
              label = {"Design Name"}
              fullWidth
              autoComplete="fname"
              onChange={handleChange("designName")}
              value={myDesign.designName}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="outlined-select-currency"
              select
              required
              error={myDesign.designChosen === ""}
              name={"designChosen"}
              label="Select"
              className={classes.textField}
              value={myDesign.designChosen}
             onChange={handleChange('designChosen')}
              fullWidth
              helperText="Please select your design"
              margin="dense"
              //variant="outlined"
            >
              {currencies.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              type={"number"}
              id="numberOfPages"
              name="numberOfPages"
              label="How many pages?"
              fullWidth
              autoComplete="number of pages"
              onChange={handleChange("numberOfPages")}
              value={myDesign.numberOfPages}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControlLabel
              label="Want to be print? "
              control={<Checkbox
                  color="secondary"
                  name="check"
                  value={myDesign.check}
                  onChange={handleChangeCheck("check")}
              />}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              error={myDesign.requirement === ""}
              id="requirement"
              name="requirement"
              label="Your requirements"
              fullWidth
              autoComplete="number of pages"
              multiline
              rowsMax={10}
              onChange={handleChange("requirement")}
              value={myDesign.requirement}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              id="address"
              name="address"
              label="address"
              fullWidth
              autoComplete="address"
              multiline
              rowsMax={2}
              onChange={handleChange("address")}
              value={myDesign.address}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              error={myDesign.city === ""}
              id="city"
              name="city"
              label="City"
              fullWidth
              autoComplete="billing address-level2"
              onChange={handleChange("city")}
              value={myDesign.city}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
                id="province"
                name="province"
                label="State/Province/Region"
                fullWidth
                onChange={handleChange("province")}
                value={myDesign.province}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="zip"
              name="postalCode"
              label="Zip / Postal code"
              fullWidth
              autoComplete="billing postal-code"
              onChange={handleChange("postalCode")}
              value={myDesign.postalCode}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              error={myDesign.country === ""}
              id="country"
              name="country"
              label="Country"
              fullWidth
              autoComplete="billing country"
              onChange={handleChange("country")}
              value={myDesign.country}
            />
          </Grid>
      </Grid>
    </React.Fragment>
  );
}



/***

 designName: '',
      designChosen: "",
      numberOfPages: "",
      check: false,
      requirement: "",
      address: "",
      city: "",
      province: "",
      postalCode: "",
      country: "",

 **/