import React from 'react';
import {MainDashboard, Contact, Orders, Payment} from './index'
import PastOrder from "./pastorders/PastOrder";


export default function contentManagement ({value}){
    let contentValue = null;

    switch (value) {
        case 0:
            //* Main Dashboard */
           contentValue = <MainDashboard/>;
           break;
        case 1:
            //* Orders */
            contentValue = <Orders/>;
            break;
        case 2:
            //* Payments */
            contentValue = <Payment/>;
            break;
        case 3:
            //* Reports */
            contentValue = <PastOrder/>;
            break;
        case 4:
            //* Contact us */
            contentValue = <Contact/>;
            break;
        default:
            //* Default */
            contentValue = <h2>Something bad happens</h2>;
}
 return contentValue
};
