import React from "react";
import PastDesign from "./PastDesign";
import PastCloud from "./PastCloud";
import PastConsultancy from "./PastConsultancy";
import PastHosting from "./PastHosting";


export default function PastOrdersContentManagement ({value}){
    let contentValue = null;

    switch (value) {
        case 9:
            //* Main Dashboard */
           contentValue = <PastDesign/>;
           break;
        case 10:
            //* Orders */
            contentValue = <PastCloud/>;
            break;
        case 11:
            //* Payments */
            contentValue = <PastHosting/>;
            break;
        case 12:
            //* Reports */
            contentValue = <PastConsultancy/>;
            break;
        default:
            //* Default */
            contentValue = <h2>Something wrong happened!</h2>;
}
 return contentValue
};
