import React from 'react';
import {TextField, Checkbox} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';


const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    width: 200,
  },
}));

const Inputf = (props) => {
     const classes = useStyles();
    let input = null;
    const [state, setState] = React.useState({
    checkedA: true,
  });

 const handleChange = name => event => {
    setState({ ...state, [name]: event.target.checked });
  };

    const {inputType, selectContent} = props;

    switch (inputType){
        case ("password"):
            input = <TextField id="outlined-password-input" type="password" variant="outlined" {...props}/>;
            break;
        case ("text"):
             input = <TextField id="outlined-text-input" type="text" variant="outlined" {...props}/>;
            break;
        case ("number") :
           input = <TextField id="outlined-number-input" type="number" variant="outlined" minimum={'0'}{...props}/>;
            break;
        case ("date"):
             input = <TextField id="outlined-date-input" type="date" variant="outlined" {...props}/>;
            break;
        case ("email"):
              input = <TextField id="outlined-email-input" type="email" variant="outlined" {...props}/>;
              break;
        case ("textarea"):
              input = <TextField id="outlined-textarea-input" multiline rowsMax={"10"} variant="outlined" {...props}/>;
              break;
        case ("select"):
            input = <TextField
        id="outlined-select-currency"
        select
        label="Select"
        className={classes.textField}
        //value={values.currency}
        onChange={handleChange('currency')}
        SelectProps={{
          MenuProps: {
            className: classes.menu,
          },
        }}
        helperText="Please select your design"
        margin="dense"
        variant="outlined"
      >
        {selectContent.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>;
            break;
        case ("checkbox"):
            input =  <Checkbox checked={state.checkedA} onChange={handleChange('checkedA')} value="checkedA" inputProps={{'aria-label': 'primary checkbox',}} {...props}  />;
            break;
        default:
            input = null;
    }

    return input;
};

export default Inputf;