import React, {Fragment, useEffect} from 'react';
import {Router, Route, Switch} from "react-router-dom";
import CssBaseline from '@material-ui/core/CssBaseline'
import {Provider} from 'react-redux';
import {history} from './utils/historyUtils'
import {authLogin, getUserProfile} from './actions/authActions'
import store from './store'
import * as routes from './routes'
import RequireAuth from "./routes/PrivateRoute";
import {SignUp, SignIn, HomePage,
  Dashboard, SignUpDone, AccountActivation, ResetPasswordDone,
  ResetPasswordConfirm, NoMatch,
  ResetPassword, ChangePasswordComponent} from './pages';

import dotenv from 'dotenv';
dotenv.config();

const token = localStorage.getItem('token');

if (token){
  store.dispatch(authLogin(token))
}


function App() {
  useEffect(() => {
    store.dispatch(getUserProfile())
  }, []);
  return (
      <div>
        <Router history={history}>
          <Fragment>
            <CssBaseline/>
            <Provider store={store}>
              <Switch>
                <Route exact={true} path={routes.HOME} component={HomePage}/>
                <Route path={routes.SIGN_UP_DONE} component={SignUpDone}/>
                <Route path={routes.SIGN_IN} component={SignIn}/>
                <Route path={routes.SIGN_UP} component={SignUp}/>
                <Route path={routes.PASSWORD_RESET} component={ResetPassword}/>
                <Route path={routes.PASSWORD_RESET_DONE} component={ResetPasswordDone}/>
                <Route path={routes.PASSWORD_RESET_CONFIRM} component={ResetPasswordConfirm}/>
                <Route path={routes.ACCOUNT_ACTIVATE} component={AccountActivation}/>
                <Route path={routes.DASHBOARD} component={RequireAuth(Dashboard)}/>
                <Route path={routes.PASSWORD_CHANGE} component={RequireAuth(ChangePasswordComponent)}/>
                <Route component={NoMatch}/>
                {/**
                 <Route exact={true} path={routes.USER_PROFILE_UPDATE} component={RequireAuth(UserProfile)}/>
                 <Route exact={true} path={routes.PROFILE} component={RequireAuth(UserProfile)}/>
                 <Route exact={true} path={routes.PROFILE_EDIT} component={RequireAuth(EditUserProfile)}/> ***/}
              </Switch>
            </Provider>
          </Fragment>
        </Router>
      </div>
  );
}

export default App;
