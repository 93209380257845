import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Consultancy from "./Consultancy";
import Cloud from "./Cloud";
import Hosting from "./Hosting";
import Design from "./Design";
import PastOrdersContentManagement from "./PastOrdersContentManagement";
import Title from "../mainDashboard/Title";


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 200,
  },
}));

export default function MainDashboard() {
  const classes = useStyles();
  const [value, setValue] = React.useState(9);
  const [selected, getSelected] = React.useState(false);

  const handleChange = (newValue) => {
    setValue(newValue);
    getSelected(selected);
    console.log(value)
  };

  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
          { selected ?
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
                <Title>You do not have yet any past orders!</Title>
            </Grid>
          </Grid>
        </Container>
        :
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            {/* Chart */}
            {/* Recent Deposits */}
            <Grid item xs={12} md={4} lg={3}>
              <Paper className={fixedHeightPaper}>
                <Design myValue={handleChange}/>
              </Paper>
            </Grid>
              <Grid item xs={12} md={4} lg={3}>
              <Paper className={fixedHeightPaper}>
                <Cloud myValue={handleChange}/>
              </Paper>
            </Grid>
              <Grid item xs={12} md={4} lg={3}>
              <Paper className={fixedHeightPaper}>
                <Hosting myValue={handleChange}/>
              </Paper>
            </Grid>
              <Grid item xs={12} md={4} lg={3}>
              <Paper className={fixedHeightPaper}>
                <Consultancy myValue={handleChange}/>
              </Paper>
            </Grid>
            {/* Recent Orders */}
            <Grid item xs={12}>
              <PastOrdersContentManagement value={value}/>
            </Grid>
          </Grid>
        </Container>
        }
      </main>
    </div>
  );
}