import React from 'react';
import Checkout from "./Hosting/CheckoutHosting";


export default function StepHosting() {

  return (
      <Checkout/>
  );
};
