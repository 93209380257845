import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from "@material-ui/core/MenuItem";
import {makeStyles} from "@material-ui/core";

const hosting = [
  {
    value: 'Shared Hosting',
    label: 'Shared Hosting',
  },
  {
    value: 'Virtual Private Server',
    label: 'Virtual Private Server',
  },
  {
    value: 'Domains Services',
    label: 'Domains Services',
  },
  {
    value: 'Maintenance',
    label: 'Maintenance',
  },
  {
    value: 'Other',
    label: 'Other',
  },
];

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    width: 200,
  },
}));

export default function CloudForm({myDesignDisable}) {
   const classes = useStyles();
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Complete the form
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            id="outlined-select-currency"
            select
            required
            name={"serviceChosen"}
            label="Select"
            className={classes.textField}
            value={myDesignDisable.serviceChosen}
            fullWidth
            helperText="Please select your service"
            margin="dense"
            disabled
            //variant="outlined"
          >
            {hosting.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="websiteAppName"
            name="websiteAppName"
            label="Website or App Name"
            fullWidth
            autoComplete="website"
            value={myDesignDisable.websiteAppName}
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          {myDesignDisable.check ?
          <FormControlLabel
            label="Want our shared hosting? "
            control={<Checkbox
                color="secondary"
                name="check"
               checked
                disabled
            />}
          /> :
          <FormControlLabel
            label="Want our shared hosting? "
            control={<Checkbox
                color="secondary"
                name="check"
                disabled
            />}
          />}
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="appFor"
            name="appFor"
            label="What is your website or App for?"
            fullWidth
            autoComplete="appFor"
            value={myDesignDisable.appFor}
            disabled
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="requirement"
            name="requirement"
            label="Your requirements"
            fullWidth
            autoComplete="requirement"
            multiline
            rowsMax={10}
            value={myDesignDisable.requirement}
            disabled
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="address"
            name="address"
            label="address"
            fullWidth
            autoComplete="address"
            multiline
            rowsMax={2}
            value={myDesignDisable.address}
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="city"
            name="city"
            label="City"
            fullWidth
            autoComplete="billing address-level2"
            value={myDesignDisable.city}
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
              id="province"
              name="province"
              label="State/Province/Region"
              fullWidth
              value={myDesignDisable.province}
              disabled
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="zip"
            name="postalCode"
            label="Zip / Postal code"
            fullWidth
            autoComplete="billing postal-code"
            value={myDesignDisable.postalCode}
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="country"
            name="country"
            label="Country"
            fullWidth
            autoComplete="billing country"
            value={myDesignDisable.country}
            disabled
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
