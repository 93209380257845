import React from 'react';
import Checkout from "./design/CheckoutDesign";


export default function StepDesign() {

  return (
      <Checkout/>
  );
};

