import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Title from './mainDashboard/Title.js'


const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));


export default function Web({handleWeb}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
        <Title>
            Websites and Cloud Applications
        </Title>
      <List component="nav" aria-label="Main mailbox folders">
        <ListItem button onClick={() => handleWeb(6)}>
          <ListItemText primary="Professional Websites" />
        </ListItem>
         <Divider />
        <ListItem button onClick={() => handleWeb(6)}>
          <ListItemText primary="Complete Cloud Applications" />
        </ListItem>
        <Divider />
        <ListItem button onClick={() => handleWeb(6)}>
          <ListItemText primary="Web APIs and REST" />
        </ListItem>
        <Divider />
        <ListItem button onClick={() => handleWeb(6)}>
           <ListItemText primary="Team Up on Projects" />
        </ListItem>
        <Divider />
        <ListItem button onClick={() => handleWeb(6)}>
           <ListItemText primary="Can't see in my list?" />
        </ListItem>
         <Divider />
      </List>
    </div>
  );
}
