import { AuthTypes } from "../constants/actionTypes";
import { AuthUrls } from "../constants/urls";
import axios from "axios";



// GET CLOUD ORDER
function setGetOrderPayments(payload) {
    return {
        type: AuthTypes.GET_DEALS,
        payload: payload
    };
}

export function getDealsTime() {
    return function(dispatch) {

        axios.get(AuthUrls.GET_DEALS).then(response => {
            dispatch(setGetOrderPayments(response.data))
        }).catch((error) => {
            // If request is bad...
            // Show an error to the user
            console.log(error);
            //
        });

    };
}