import { AuthTypes } from "../constants/actionTypes";
import { AuthUrls } from "../constants/urls";
import store from "../store";
import { getUserToken } from "../utils/authUtils";
import axios from "axios";




// GET CONSULTANCY ORDER
function setGetConsultancyOrder(payload) {
    return {
        type: AuthTypes.GET_CONSULTANCY_ORDER,
        payload: payload
    };
}

export function getConsultancyOrder() {
    return function(dispatch) {
        const token = getUserToken(store.getState());
        if (token) {
            axios.get(AuthUrls.GET_CONSULTANCY_ORDER, {
                headers: {
                    authorization: 'Token ' + token
                }
            }).then(response => {
                dispatch(setGetConsultancyOrder(response.data))
            }).catch((error) => {
                // If request is bad...
                // Show an error to the user
                console.log(error);
                //
            });
        }
    };
}

// DELETE CONSULTANCY ORDER
function setDeleteConsultancyOrder(id) {
    return {
        type: AuthTypes.DELETE_CONSULTANCY_ORDER,
        payload: id
    };
}

export function deleteConsultancyOrder(id) {
    return function(dispatch) {
        const token = getUserToken(store.getState());
        if (token) {
            axios.delete(AuthUrls.DELETE_CONSULTANCY_ORDER + id, {
                headers: {
                    authorization: 'Token ' + token
                }
            }).then(response => {
                dispatch(setDeleteConsultancyOrder(id))
            }).catch((error) => {
                // If request is bad...
                // Show an error to the user
                console.log(error);
                //
            });
        }
    };
}

// ADD CONSULTANCY ORDER
function setAddConsultancyOrder(payload) {
    return {
        type: AuthTypes.ADD_CONSULTANCY_ORDER,
        payload: payload
    };
}

export function addConsultancyOrder(formValues) {
    return function(dispatch) {
        const token = getUserToken(store.getState());
        if (token) {
            axios.post(AuthUrls.ADD_CONSULTANCY_ORDER, formValues, {
                headers: {
                    authorization: 'Token ' + token
                }
            }).then(response => {
                dispatch(setAddConsultancyOrder(response.data))
            }).catch((error) => {
                // If request is bad...
                // Show an error to the user
                console.log(error);
                //
            });
        }
    };
}

// SEND NOTIFICATIONS
function setEmail() {
    return {
        type: AuthTypes.SEND_EMAIL_CONSULTANCY,
    };
}

export function getNotification() {
    return function(dispatch) {
        const token = getUserToken(store.getState());
        if (token) {
            axios.get(AuthUrls.SEND_EMAIL_CONSULTANCY, {
                headers: {
                    authorization: 'Token ' + token
                }
            }).then(response => {
                dispatch(setEmail())
            }).catch((error) => {
                // If request is bad...
                // Show an error to the user
                console.log(error);
                //
            });
        }
    };
}