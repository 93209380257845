import {Grid, Paper} from '@material-ui/core'
import React from 'react'
import Gridf from './Grid'
import Inputf from './Input'

const ContainerColumn = props => <Grid container={true} direction={'column'} alignContent={'center'} {...props}/> ;
const ContainerRow = props => <Grid container={true} direction={'row'} justify={'center'} {...props}/> ;
const ContainerRowStart = props => <Grid container={true} direction={'row'} justify={'flex-start'} {...props}/> ;
const ContainerSimple = props => <Grid container={true} {...props}/> ;
const ContainerRowLineCentered = props => <Grid container direction={'row'} justify={'center'} alignItems={'center'} {...props}/>;

const ItemSimple = props => <Grid item={true} {...props}/>;
const ItemAuto = props => <Grid item={true} xs={'auto'} sm={'auto'} md={'auto'} lg={'auto'} xl={'auto'} {...props}/>;

const PaperAfterContainer = props => <Paper {...props}/>;

export {
    ContainerColumn,
    ContainerRow,
    ContainerSimple,
    ItemAuto,
    ItemSimple,
    PaperAfterContainer,
    ContainerRowStart,
    ContainerRowLineCentered,
    Gridf,
    Inputf
}