const ROOT_URL = "https://api.djeumene.ca/";

export const AuthUrls = {
    LOGIN: `${ROOT_URL}rest-auth/login/`,
    SIGNUP: `${ROOT_URL}rest-auth/registration/`,
    CHANGE_PASSWORD: `${ROOT_URL}rest-auth/password/change/`,
    RESET_PASSWORD: `${ROOT_URL}rest-auth/password/reset/`,
    RESET_PASSWORD_CONFIRM: `${ROOT_URL}rest-auth/password/reset/confirm/`,
    USER_ACTIVATION: `${ROOT_URL}rest-auth/registration/verify-email/`,
    USER_PROFILE: `${ROOT_URL}rest-auth/user/`,
    GET_DESIGN_ORDERS: `${ROOT_URL}design/api/`,
    DELETE_DESIGN_ORDER: `${ROOT_URL}design/api/`,
    ADD_DESIGN_ORDER: `${ROOT_URL}design/api/`,
    ADD_CLOUD_ORDER: `${ROOT_URL}cloud/api/`,
    DELETE_CLOUD_ORDER: `${ROOT_URL}cloud/api/`,
    GET_CLOUD_ORDER: `${ROOT_URL}cloud/api/`,
    ADD_HOSTING_ORDER: `${ROOT_URL}hosting/api/`,
    DELETE_HOSTING_ORDER: `${ROOT_URL}hosting/api/`,
    GET_HOSTING_ORDER: `${ROOT_URL}hosting/api/`,
    ADD_CONSULTANCY_ORDER: `${ROOT_URL}consultancy/api/`,
    DELETE_CONSULTANCY_ORDER: `${ROOT_URL}consultancy/api/`,
    GET_CONSULTANCY_ORDER: `${ROOT_URL}consultancy/api/`,
    ADD_EMAIL_ORDER: `${ROOT_URL}contact/api/`,
    GET_EMAIL_ORDER: `${ROOT_URL}contact/api/`,
    DELETE_EMAIL_ORDER: `${ROOT_URL}contact/api/`,
    GET_RESPONSE_ORDER: `${ROOT_URL}response/api/`,
    SEND_EMAIL_DESIGN: `${ROOT_URL}design/email/`,
    SEND_EMAIL_CLOUD: `${ROOT_URL}cloud/email/`,
    SEND_EMAIL_HOSTING: `${ROOT_URL}hosting/email/`,
    SEND_EMAIL_CONSULTANCY: `${ROOT_URL}consultancy/email/`,
    SEND_EMAIL_CONTACT: `${ROOT_URL}contact/email/`,
    GET_ORDERS_PAYMENTS: `${ROOT_URL}orders_payments/api/`,
    GET_DEALS: `${ROOT_URL}deals/api/`,
};