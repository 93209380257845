import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import StepContentManagement from '../forms/StepContentManagement'
import {Design, Hosting, Web, Consultancy, Tools} from './index'



const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 335,
  },
}));

export default function Orders() {
  const classes = useStyles();
   const [design, setDesign] = React.useState(true);
   const [constant, setConstant] = React.useState(5);
   const [web, setWeb] = React.useState(true);
   const [hosting, setHosting] = React.useState(true);
   const [consultancy, setConsultancy] = React.useState(true);

   function handleDesign(newConstant) {
    setDesign(!design);
    setConstant(newConstant);
    console.log(`The new constant is ${constant}`)
  }
  function handleWeb(newConstant) {
    setWeb(!web);
    setConstant(newConstant);
    console.log(`The new constant is ${constant}`)
  }
  function handleHosting(newConstant) {
    setHosting(!hosting);
    setConstant(newConstant);
    console.log(`The new constant is ${constant}`)
  }
  function handleConsultancy(newConstant) {
    setConsultancy(!consultancy);
    setConstant(newConstant);
    console.log(`The new constant is ${constant}`)
  }

  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          { design && web && hosting && consultancy ?
          <Grid container spacing={3}>
            {/* Design */}
            <Grid item xs={12} md={4} lg={4}>
              <Paper className={fixedHeightPaper}>
                <Design handleDesign={handleDesign} constant={constant}/>
              </Paper>
            </Grid>
              {/* Websites and Clouds */}
            <Grid item xs={12} md={4} lg={4}>
              <Paper className={fixedHeightPaper}>
                <Web handleWeb={handleWeb} constant={constant}/>
              </Paper>
            </Grid>
              {/* Hosting */}
            <Grid item xs={12} md={4} lg={4}>
              <Paper className={fixedHeightPaper}>
                <Hosting handleHosting={handleHosting} constant={constant}/>
              </Paper>
            </Grid>
            {/* Consultancy */}
            <Grid item xs={12} md={4} lg={4}>
              <Paper className={fixedHeightPaper}>
                <Consultancy handleConsultancy={handleConsultancy} constant={constant}/>
              </Paper>
            </Grid>
            {/* Recent Orders */}
            <Grid item xs={12} md={8} lg={8}>
              <Paper className={fixedHeightPaper}>
                <Tools/>
              </Paper>
            </Grid>
          </Grid>
          : <Grid container spacing={3}>
            {/* Design */}
            <Grid item xs={12} md={8} lg={8}>
              <Paper elevation={0}>
                <StepContentManagement constant={constant}/>
              </Paper>
            </Grid>
          </Grid>}
        </Container>
      </main>
    </div>
  );
}

//className={fixedHeightPaper}