import {Grid, Paper, Typography} from "@material-ui/core";
import React, {Component, Fragment} from "react";
import {withStyles} from "@material-ui/core";
import {dbsql, javasepro, javaass} from '../../common/images/'
import {useStyles} from "../../common/styles";
import classNames from "classnames";
import {Line} from '../../components'



const tutorialSteps = [
  {
    label: 'Our Certifications',
    imgPath:
      'https://images.unsplash.com/photo-1474649107449-ea4f014b7e9f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80'
  }
];


export default withStyles(useStyles)(class Team extends Component {
     handleCopyRight=(e)=> {
         e.preventDefault();
         return false;
     };
       render(){
          const {classes} = this.props;
        return (
            <Fragment>
                <div className={classes.dealWithUsSpace}>
                    <Paper className={classes.paper} elevation={0}>
                        <Line/>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={12} md={12} container direction={'column'} justify={'center'} alignItems={'flex-start'}>
                            <Grid item direction={'column'} justify={'center'} alignItems={'center'}>
                              <Typography variant={'overline'} className={classNames(classes.title)}>
                                 professional certified team
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Paper>
                    <Grid container direction={'row'} color={'secondary'} justify={"center"} spacing={6} >
                        <Grid item>
                             <Paper elevation={0}>
                                <img  src={javasepro} alt={tutorialSteps[0].label}  onContextMenu={this.handleCopyRight} onMouseDown={(e) => e.preventDefault()}/>
                            </Paper>
                        </Grid>
                        <Grid item>
                             <Paper  elevation={0} onContextMenu={this.handleCopyRight}>
                                <img className={classes.imgTest} src={javaass} alt={tutorialSteps[0].label} onContextMenu={this.handleCopyRight} onMouseDown={(e) => e.preventDefault()}/>
                            </Paper>
                        </Grid>
                        <Grid item>
                             <Paper  elevation={0} onContextMenu={this.handleCopyRight}>
                                <img className={classes.imgTest} src={dbsql} alt={tutorialSteps[0].label} onContextMenu={this.handleCopyRight} onMouseDown={(e) => e.preventDefault()}/>
                            </Paper>
                        </Grid>
                    </Grid>
                </div>
            </Fragment>
        )
    }
})
