import React from 'react';
import Checkout from "./Consultancy/CheckoutConsultancy";


export default function StepDesign() {

  return (
      <Checkout/>
  );
};
