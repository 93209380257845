import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {Typography, Button} from '@material-ui/core';
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Divider from '@material-ui/core/Divider';
import CheckoutEmails from "../forms/contactus/CheckoutEmails";
import clsx from "clsx";
import moment from 'moment';
import EmailsSent from "../forms/contactus/EmailsSent";
//import EmailsReceived from "../forms/contactus/EmailsReceived";
import {connect} from "react-redux";


function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const useStyles = makeStyles(theme => ({
  // appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
    container1: {
    paddingBottom: theme.spacing(4),
  },
    date: {
    paddingLeft: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  marBottom: {
    marginBottom: theme.spacing(4),
  },
}));


function Contact(props) {
  const classes = useStyles();
  const {emailMapped} = props;
  const [value, setValue] = React.useState(0);
  const [email, setEmail] = React.useState(true);
  const [numberEmail] = React.useState(false);

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  function handleEmail() {
    setEmail(!email);
    console.log(emailMapped);
    console.log(emailMapped.length)
  }

  /*****
  useEffect(() => {
     if(emailMapped.length !== 0){
    setNumberEmail(!numberEmail)
  }
   }, [emailMapped.length, numberEmail]);

   *****/
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  return (
      <div className={classes.root}>
      <CssBaseline />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />

        <Container maxWidth="lg" className={classes.container}>
          <Typography color={'textPrimary'} gutterBottom={true} variant={'body2'} className={classes.date}>
             {`Hi there, today is ${moment().format("LL")}`}
          </Typography>
          <Typography color={'textPrimary'} gutterBottom={true} variant={'h6'} className={classes.date}>
             Contact us Quickly
          </Typography>
          <Grid container spacing={3}>
            {/* Chart */}
            <Grid item xs={12} md={8} lg={9}>
              {email ?
              <Paper className={fixedHeightPaper} elevation={0}>
                 <AppBar position="static" color="default">
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      indicatorColor="primary"
                      textColor="primary"
                      variant="scrollable"
                      scrollButtons="on"
                    >
                      <Tab label="Compose and send an email" />
                    </Tabs>
                  </AppBar>
                  {value === 0 && <TabContainer>
                    { numberEmail ?
                     <Typography variant={'subtitle1'} gutterBottom={true} className={classes.marBottom}>
                      It's really easy to contact, just click the button below and compose an email
                      </Typography>
                     : <EmailsSent/> }
                      <Divider className={classes.marBottom}/>
                      <Button color={'primary'} size={'medium'} variant={'contained'} onClick={() => handleEmail()}>
                          Message us
                      </Button>
                  </TabContainer>}
              </Paper>
              : <CheckoutEmails/>}
            </Grid>
          </Grid>
        </Container>
      </main>
    </div>
  );
}

const mapStateToProps = state => ({
  emailMapped: state.contactReducer.contact,
});

Contact.propTypes = {
  emailMapped: PropTypes.array.isRequired,
};

export default connect(mapStateToProps)(Contact);

