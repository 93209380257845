import { AuthTypes } from "../constants/actionTypes";
import { AuthUrls } from "../constants/urls";
import store from "../store";
import { getUserToken } from "../utils/authUtils";
import axios from "axios";




// GET CLOUD ORDER
function setGetOrderPayments(payload) {
    return {
        type: AuthTypes.GET_ORDERS_PAYMENTS,
        payload: payload
    };
}

export function getOrderPayments() {
    return function(dispatch) {
        const token = getUserToken(store.getState());
        if (token) {
            axios.get(AuthUrls.GET_ORDERS_PAYMENTS, {
                headers: {
                    authorization: 'Token ' + token
                }
            }).then(response => {
                dispatch(setGetOrderPayments(response.data))
            }).catch((error) => {
                // If request is bad...
                // Show an error to the user
                console.log(error);
                //
            });
        }
    };
}