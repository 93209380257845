import { AuthTypes } from "../constants/actionTypes";
import { AuthUrls } from "../constants/urls";
import store from "../store";
import { getUserToken } from "../utils/authUtils";
import axios from "axios";




// GET CLOUD ORDER
function setGetCloudOrder(payload) {
    return {
        type: AuthTypes.GET_CLOUD_ORDER,
        payload: payload
    };
}

export function getCloudOrder() {
    return function(dispatch) {
        const token = getUserToken(store.getState());
        if (token) {
            axios.get(AuthUrls.GET_CLOUD_ORDER, {
                headers: {
                    authorization: 'Token ' + token
                }
            }).then(response => {
                dispatch(setGetCloudOrder(response.data))
            }).catch((error) => {
                // If request is bad...
                // Show an error to the user
                console.log(error);
                //
            });
        }
    };
}

// DELETE CLOUD ORDER
function setDeleteCloudOrder(id) {
    return {
        type: AuthTypes.DELETE_CLOUD_ORDER,
        payload: id
    };
}

export function deleteCloudOrder(id) {
    return function(dispatch) {
        const token = getUserToken(store.getState());
        if (token) {
            axios.delete(AuthUrls.DELETE_CLOUD_ORDER + id, {
                headers: {
                    authorization: 'Token ' + token
                }
            }).then(response => {
                dispatch(setDeleteCloudOrder(id))
            }).catch((error) => {
                // If request is bad...
                // Show an error to the user
                console.log(error);
                //
            });
        }
    };
}

// ADD CLOUD ORDER
function setAddCloudOrder(payload) {
    return {
        type: AuthTypes.ADD_CLOUD_ORDER,
        payload: payload
    };
}

export function addCloudOrder(formValues) {
    return function(dispatch) {
        const token = getUserToken(store.getState());
        if (token) {
            axios.post(AuthUrls.ADD_CLOUD_ORDER, formValues, {
                headers: {
                    authorization: 'Token ' + token
                }
            }).then(response => {
                dispatch(setAddCloudOrder(response.data))
            }).catch((error) => {
                // If request is bad...
                // Show an error to the user
                console.log(error);
                //
            });
        }
    };
}


// SEND NOTIFICATIONS
function setEmail() {
    return {
        type: AuthTypes.SEND_EMAIL_CLOUD,
    };
}

export function getNotification() {
    return function(dispatch) {
        const token = getUserToken(store.getState());
        if (token) {
            axios.get(AuthUrls.SEND_EMAIL_CLOUD, {
                headers: {
                    authorization: 'Token ' + token
                }
            }).then(response => {
                dispatch(setEmail())
            }).catch((error) => {
                // If request is bad...
                // Show an error to the user
                console.log(error);
                //
            });
        }
    };
}