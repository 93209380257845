import { AuthTypes } from "../constants/actionTypes";

const initialState = {
    consultancyOrder: []
};

export default function(state = initialState, action) {
    switch(action.type) {
        case AuthTypes.GET_CONSULTANCY_ORDER:
            return { ...state, consultancyOrder: action.payload};
        case AuthTypes.DELETE_CONSULTANCY_ORDER:
            return { ...state, consultancyOrder: state.consultancyOrder.filter(consultancyId => consultancyId.id !== action.payload)};
        case AuthTypes.ADD_CONSULTANCY_ORDER:
            return { ...state, consultancyOrder: [...state.consultancyOrder, action.payload]};
        default:
            return state;
    }
}