import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import * as routes from '../routes'
import PropTypes from 'prop-types'
import {withStyles, Typography, Button} from '@material-ui/core'
import classNames from 'classnames'
import {useStyles} from '../common/styles'
import {monquo,robotSvg} from '../common/images'
import {ContainerRowLineCentered, PaperAfterContainer, ItemSimple, ContainerRowStart} from '../components/abstractions'

import { reduxForm, Field} from "redux-form";
import {signupUser} from "../actions/authActions";
import {renderTextField, validate} from "../utils/renderUtils";
import asyncValidate from "../utils/asyncValidate";

class SignUp extends Component {

    render(){
        const {classes, handleSubmit} = this.props;


        this.handleCopyRight=(e)=> {
           e.preventDefault();
           return false;
         };


        return(
            <div className={classes.root}>
                <ContainerRowLineCentered>
                    <ItemSimple xs={12} sm={6} md={6}>
                        <img src={robotSvg} alt={"Robot Engineering"} className={classes.robot} onContextMenu={this.handleCopyRight} onMouseDown={(e) => e.preventDefault()}/>
                    </ItemSimple>
                    <ItemSimple xs={12} sm={6} md={6}>
                        <PaperAfterContainer className={classes.paperWidthMd}>
                            <Typography gutterBottom={true} align={'center'}>
                               <img src={monquo} alt={'logo'} onContextMenu={this.handleCopyRight} onMouseDown={(e) => e.preventDefault()}/>
                            </Typography>
                            <Typography gutterBottom={true} align={'center'} variant={'h6'}>
                             Please register here!
                            </Typography>
                            <form method={'post'} onSubmit={handleSubmit}>
                                <Field
                                    variant="outlined"
                                    label="Email Address"
                                    name="email"
                                    type={'email'}
                                    autoComplete="email"
                                    component={renderTextField}
                                  />
                                 <Field
                                    variant="outlined"
                                    label="Username"
                                    name="username"
                                    type={'text'}
                                    autoComplete="username"
                                    component={renderTextField}
                                  />
                                 <Field
                                    variant="outlined"
                                    label="Password"
                                    name="password1"
                                    type={'password'}
                                    autoComplete="Password"
                                    component={renderTextField}
                                  />
                                  <Field
                                    variant="outlined"
                                    label="Confirm Password"
                                    name="password2"
                                    type={'password'}
                                    autoComplete="Password2"
                                    component={renderTextField}
                                  />
                                <Button
                                    type={'submit'}
                                    color={'secondary'}
                                    variant={'contained'}
                                    size={'medium'}
                                    fullWidth={true}
                                    className={classNames(classes.mar, classes.bigMar)}
                                >
                                    register
                                </Button>
                            </form>
                            <ContainerRowStart>
                                <ItemSimple>
                                    <Typography gutterBottom={true}>
                                        Already have an account? <Link to={routes.SIGN_IN}>Please login </Link>
                                    </Typography>
                                </ItemSimple>
                            </ContainerRowStart>
                        </PaperAfterContainer>
                    </ItemSimple>
                </ContainerRowLineCentered>
            </div>
        )
    }
}

SignUp.propTypes = {
    classes: PropTypes.object.isRequired,
};

const validateForm = values => {
    const errors = {};
    const { password1, password2 } = values;
    if (password1 !== password2) {
        errors.password2 = "Password does not match."
    }
    return errors;
};

export default reduxForm({
    form: "signup",
    validate: validate,
    onSubmit: signupUser,
    asyncValidate,
     validateForm
})(withStyles(useStyles)(SignUp));
