import React, {useEffect} from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import {cardBlackImage, cardBlueImage, cardGrayImage, cardWhiteImage} from '../images'
import CardTemplate from "./CardTemplate";

import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import {getDealsTime} from "../../../actions/dealsTimeActions";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const tutorialSteps = [
    {
        index: 1,
        background_image: cardBlueImage,
        time: 'Limited time & Support 24/7'
    },
    {
        index: 2,
        background_image: cardGrayImage,
        time: 'Limited time & Support 24/7'
    },
    {
        index: 3,
        background_image: cardBlackImage,
        time: 'Limited time & Support 24/7'
    },
    {
        index: 4,
        background_image: cardWhiteImage,
        time: 'Limited time & Support 24/7'
    },
];

const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: "100%",
        flexGrow: 1,
        marginTop: 32,
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        height: 50,
        paddingLeft: theme.spacing(4),
        backgroundColor: theme.palette.background.default,
    },
    img: {
        height: 255,
        display: 'block',
        maxWidth: 400,
        overflow: 'hidden',
        width: '100%',
    },
}));

function PromotionPrix(props) {
    const { getDealsTime, DealsTimeMapped, onGettingTitle, getTitleFromChild} = props;

    useEffect(() => {
        getDealsTime();
    }, [getDealsTime]);

    const classes = useStyles();
    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const newDistributedArray = tutorialSteps.map((item, i) => Object.assign({}, item, DealsTimeMapped[i]));
    const maxSteps = newDistributedArray.length;
    onGettingTitle(newDistributedArray[0].deal_title);
    getTitleFromChild(newDistributedArray[0].title);

    const handleNext = () => {
        setActiveStep(prevActiveStep => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep(prevActiveStep => prevActiveStep - 1);
    };

    const handleStepChange = step => {
        setActiveStep(step);
    };

    return (
        <div className={classes.root}>
            <AutoPlaySwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={activeStep}
                onChangeIndex={handleStepChange}
                enableMouseEvents
            >
                {newDistributedArray.map((step, index) => (
                    <div key={step.index}>
                        {Math.abs(activeStep - index) <= 2 ? (
                            <CardTemplate
                                card_title={step.deal_card_title}
                                card_title_first_line={step.deal_card_title_first_line}
                                background_image={step.background_image}
                                card_amount={step.deal_card_amount}
                                expire_date={step.expire_date}
                                time={step.time}
                            />
                        ) : null}
                    </div>
                ))}
            </AutoPlaySwipeableViews>
            <MobileStepper
                steps={maxSteps}
                position="static"
                variant="dots"
                activeStep={activeStep}
                backButton={
                    <Button size="small" onClick={handleBack} disabled={activeStep === 0}>{/*
                        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                        Back*/
                    }</Button>
                }
                nextButton={
                    <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>{/*
                        Next
                        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}*/
                    }</Button>
                }
            />
        </div>
    );
}

const mapStateToProps = state => ({
    DealsTimeMapped: state.dealsTimeReducer.dealsTime,
});

PromotionPrix.propTypes = {
    DealsTimeMapped: PropTypes.array.isRequired,
    getDealsTime: PropTypes.func.isRequired
};

export default connect(mapStateToProps,
    {getDealsTime})(PromotionPrix);